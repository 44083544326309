import { withoutHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
import axios from 'axios';
import { FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL } from './Constants';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withoutHeader.baseURL;
axiosDefaults.headers = withoutHeader.headers;
axiosDefaults.timeout = withoutHeader.timeOut;

/*
    API REQUEST 
 */
export const forgotPasswordRequest = (value) => {
    return async dispatch =>

        axios.post(`${webConst.FORGOT_PASSWORD}`, value)
            .then((response) => {
                if(response.status===200)
                {
                    dispatch(forgotPasswordSuccessResult(response.data.data,200,true));
                }
                else{
                    dispatch(forgotPasswordFailResult(response.data.data,response.status,false));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(forgotPasswordFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
};


/*
    API RESULT
 */

export const forgotPasswordSuccessResult = (success,status) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    success,
    status
});

export const forgotPasswordFailResult = (error,status) => ({
    type: FORGOT_PASSWORD_FAIL,
    error,
    status
});
