import { FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL } from './Constants';

const initialState = {
    success: [],
    fail: '',
    status: ''
};

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                success: action.success,
                fail: '',
                status: action.status
            };
        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                success: [],
                fail: action.error,
                status: action.status
            };
        default:
            return state;
    }
}
