import { TIME_SLOT_SUCCESS, TIME_SLOT_FAIL,ADD_TIME_SLOT_SUCCESS,ADD_TIME_SLOT_FAIL,UPDATE_TIME_SLOT_SUCCESS,UPDATE_TIME_SLOT_FAIL,DELETE_TIME_SLOT_SUCCESS,DELETE_TIME_SLOT_FAIL} from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getTimeSlotRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.TIME_SLOT}?`+value)
            .then((response) => {
                if(response.status===200)
                {
                    dispatch(timeSlotSuccessResult(response.data,response.status));
                }
                else{
                    dispatch(timeSlotFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(timeSlotFailResult(error.response.data.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

export const addTimeSlotRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.TIME_SLOT}`, value)
            .then((response) => {
                if(response.status===200){
                    dispatch(addTimeSlotSuccessResult(response.data.data,response.status));
                }
                else {
                    dispatch(addTimeSlotFailResult(response.data.message,response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(addTimeSlotFailResult(error.response.data.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

export const updateTimeSlotRequest = (id,value) => {
    return dispatch => {
        axios.put(`${webConst.TIME_SLOT}/`+id, value)
            .then((response) => {
                if(response.status===200){
                    dispatch(updateTimeSlotSuccessResult(response.data.data,response.status));
                }
                else {
                    dispatch(updateTimeSlotFailResult(response.data.message,response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(updateTimeSlotFailResult(error.response.data.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};


export const deleteTimeSlotRequest = (id) => {
    return dispatch => {
        axios.delete(`${webConst.TIME_SLOT}/`+id)
            .then((response) => {
                if(response.status===200){
                    dispatch(deleteTimeSlotSuccessResult(response.data.data,response.status));
                }
                else {
                    dispatch(deleteTimeSlotFailResult(response.data.message,response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(deleteTimeSlotFailResult(error.response.data.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

/*
    API RESULT
 */

export const timeSlotSuccessResult = (success, status) => ({
    type: TIME_SLOT_SUCCESS,
    success,
    status
});

export const timeSlotFailResult = (error, status) => ({
    type: TIME_SLOT_FAIL,
    error,
    status
});


export const addTimeSlotSuccessResult = (success, status) => ({
    type: ADD_TIME_SLOT_SUCCESS,
    success,
    status
});

export const addTimeSlotFailResult = (error, status) => ({
    type: ADD_TIME_SLOT_FAIL,
    error,
    status
});


export const updateTimeSlotSuccessResult = (success, status) => ({
    type: UPDATE_TIME_SLOT_SUCCESS,
    success,
    status
});

export const updateTimeSlotFailResult = (error, status) => ({
    type: UPDATE_TIME_SLOT_FAIL,
    error,
    status
});

export const deleteTimeSlotSuccessResult = (success, status) => ({
    type: DELETE_TIME_SLOT_SUCCESS,
    success,
    status
});

export const deleteTimeSlotFailResult = (error, status) => ({
    type: DELETE_TIME_SLOT_FAIL,
    error,
    status
});