import React, { Component } from 'react';
import './Forget.scss';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../utils/WebConstant';
import { NavLink } from "react-router-dom";
import * as routes from '../../../utils/RouteConstant';
import { forgotPasswordRequest } from '../../../containers/Auth/ForgotPasswordContainer/action';

class Forget extends Component {

	constructor(props) {
		super(props);

		// set the state of the app
		this.state = {
			email: '',
			error: '',
			isLoading: false,
			isValidated: false
		}
		// bind component with event handlers
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}


	componentDidUpdate(prevProps) {
		const { forgotPasswordSuccess, forgotPasswordFail, forgotPasswordStatus } = this.props;
		if (forgotPasswordStatus === 200) {
			if (prevProps.forgotPasswordSuccess !== this.props.forgotPasswordSuccess) {
				this.setState({
					formError: '',
					forgotPasswordSuccess: forgotPasswordSuccess
				}, () => {
					let _this = this;
					setTimeout(() => {
					  _this.props.history.push("/login");
					}, 500);
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="alert-message.forgot-password-success" />);
				});
				this.forceUpdate();
			}
			if (this.state.isValidated === false) {
				this.setState({isValidated: true});
			}
			if (this.state.isLoading === true) {
				this.setState({isLoading: false});
			}
		}
		else {
			if (prevProps.forgotPasswordFail !== forgotPasswordFail) {
				if (this.state.isLoading) {
					this.setState({
						forgotPasswordSuccess: '',
						formError: forgotPasswordFail,
						isLoading: false,
						isValidated: true
					});
				}
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
			}
		}
	}

	// event to handle input change
	handleChange = (name, value) => {
		this.setState({ 'error': '' });
		this.setState({ [name]: value });
	}

	// event to handle form submit
	async handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "isValidated": false });
		} else {
			const params = new URLSearchParams();
			params.append('email', this.state.email);
			this.props.forgotPasswordRequest(params);
			this.setState({ 'isLoading': true });
		}
		console.log("isLoading:",this.state.isLoading);
		this.setState({ "isValidated": true });
	}

	showError(fieldname) {
		return this.state.formError && this.state.formError && this.state.formError[fieldname] ? <span className="text-danger">{this.state.formError[fieldname]}</span> : '';
	}

	render() {
		return (
			<div className="validi-login-layout-main validi-forget-layout">
				<div className="validi-login-form-wrapper">
					<div className="validi-logo-block">
						<img src="/images/logo.png" alt="" />
						<h3 className="main-title">App Validi</h3>
					</div>
					<div className="validi-forget-details">
						<h4><Translate id="forgot-password.title" /></h4>
						<p><Translate id="forgot-password.description" /></p>
					</div>
					<Form noValidate validated={this.state.isValidated} onSubmit={this.handleSubmit}>
						<Form.Group className="form-group" controlId="formBasicPassword">
							<Form.Label><Translate id="forgot-password.email-address" /></Form.Label>
							<Translate>
								{({ translate }) =>
									<Form.Control
										type="email"
										name="email"
										className="form-control"
										value={this.state.email ? this.state.email : ''}
                    					onChange={(e) => { this.handleChange(e.target.name, e.target.value) }}
										placeholder={translate("forgot-password.email-address")}
										required
									/>
								}
							</Translate>
							<Form.Control.Feedback type="invalid"><Translate id="alert-message.email-validation" /></Form.Control.Feedback>
						</Form.Group>
						<div className="">
							<NavLink to={routes.LOGIN_ROUTE} className="forgot-link"><Translate id="login.title"></Translate></NavLink>
							<button className="validi-theme-btn">
								{webConst.isLoader(this.state.isLoading, <Translate id="forgot-password.reset-password" />)}
							</button>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		forgotPasswordSuccess: state.forgotPassword.success,
		forgotPasswordFail: state.forgotPassword.fail,
		forgotPasswordStatus: state.forgotPassword.status
	};
}

const mapDispatchToProps = { forgotPasswordRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Forget);
