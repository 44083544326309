import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Table, Form, FormGroup, Label, Input, Alert, Button, Card } from 'react-bootstrap';
import './DetoxDetail.scss';
import { Link } from 'react-router-dom';
import * as webConst from '../../../../utils/WebConstant';
import * as routes from '../../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import backArrow from '../../../../images/back-arrow.svg';
import { getDetoxPlanDetailRequest, updateDetoxDosageRequest, detoxSuspendRequest } from '../../../../containers/Medicines/DetoxPlanContainer/action';
import moment from "moment";
import { DETOX_PLAN_DETAIL_SUCCESS, UPDATE_DETOX_DOSAGE_SUCCESS, DETOX_SUSPEND_SUCCESS, DETOX_SUSPEND_FAIL } from '../../../../containers/Medicines/DetoxPlanContainer/Constants';
import suspendImage from "../../../../images/suspened.png";
import { confirmAlert } from 'react-confirm-alert'; // Import
import tickIcon from "../../../../images/check.png";
import Medicine from '../../Medicine';

class DetoxDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			'isDetoxLoading': true,
			'clientId': '',
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			detox: {},
			header: [],
			dosages: [],
			dbDosages: [],
			editDosage: [],
			tempDetoxDosage: [],
			isEditedDosage: false
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
			this.getDetoxDetail();
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { detoxSuccess, detoxStatus, detoxType } = this.props;
		if (detoxStatus === 200) {
			if (detoxType == DETOX_PLAN_DETAIL_SUCCESS) {
				if (prevProps.detoxSuccess !== detoxSuccess) {
					this.setState({
						isDetoxLoading: false,
					});

					if (detoxSuccess.detox) {
						this.setState({ detox: detoxSuccess.detox, timeSlots: detoxSuccess.timeslots, dosages: detoxSuccess.detox.records, dbDosages: detoxSuccess.detox.detox_dosages });
						// this.setState({ detox: detoxSuccess.detox, dosages: detoxSuccess.dosages.records, header: detoxSuccess.dosages.slots, dbDosages: detoxSuccess.dbDosages, tempDetoxDosage: detoxSuccess.pre_detox_dosage });
					}
				}
			}
			if (detoxType == UPDATE_DETOX_DOSAGE_SUCCESS) {
				webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.update-dosage-message" />);
				this.getDetoxDetail();
			}
			if (detoxType == DETOX_SUSPEND_SUCCESS && prevProps.detoxSuccess !== detoxSuccess) {
				let detox = this.state.detox;
				let detoxStatus = parseInt(detoxSuccess.detox.status);
				if (detoxStatus === 1) {
					detox.status = 1;
					this.setState({ detox: detox });
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.suspend-message" />);
				}
			}
		}

		if (detoxStatus === 400) {
			if (this.state.isDetoxLoading) {
				this.setState({
					isDetoxLoading: false,
				});
			}

			if (detoxType == DETOX_SUSPEND_FAIL) {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
			}
		}
	}

	getDetoxDetail = () => {
		let detoxId = this.props.match.params.detoxId;
		this.props.getDetoxPlanDetailRequest(detoxId);
	}

	editDosageRecord = (date, slotId, amount, dosageId) => {
		let editDosage = {};
		if (!editDosage[date])
			editDosage[date] = "";
		if (!editDosage[date][slotId])
			editDosage[date][slotId]["amount"] = 0;
		editDosage[date][slotId] = { amount: amount, dosageId: dosageId };
		this.setState({ editDosage: editDosage, isEditedDosage: true });
		console.log("editDosage", editDosage);
	}

	updateDosage = (dosageId, amount) => {
		let detoxId = this.props.match.params.detoxId;
		const params = new URLSearchParams();
		params.append('dosage_id', dosageId);
		params.append('dosage_amount', amount);
		this.props.updateDetoxDosageRequest(params);
		this.setState({ editDosage: [], isEditedDosage: false });
	}

	suspendDetox = (status) => {
		let detoxId = this.props.match.params.detoxId;
		let _this = this;
		let options = {
			closeOnEscape: true,
			closeOnClickOutside: true,
			customUI: ({ onClose }) => {
				return (
					<Card className="pt-3 p-2">
						<Card.Body>
							<Card.Text className='pb-2'>
								<strong>{status === 1 ? "Do you want to suspend and copy this detox medicine plan?" : "Do you want to suspend this detox medicine plan?"}</strong>
							</Card.Text>
							<div className="d-flex flex-row bd-highlight mb-3">
								<div className="p-2 bd-highlight">
									<button className='validi-theme-btn align-baseline'
										onClick={() => {
											if (detoxId) {
												const params = new URLSearchParams();
												// params.append('is_copy', status);
												params.append('id', detoxId);
												_this.props.detoxSuspendRequest(params);
												if (status === 1)
													_this.props.history.push(routes.DETOX_SUSPEND_ROUTE + "/" + detoxId);
												else
													_this.props.history.push(routes.DETOX_DETAIL_ROUTE + "/" + detoxId);
												onClose();
											}
										}}
									> Ja</button>
								</div>
								<div className="p-2 bd-highlight"><button className='validi-theme-btn align-middle'
									onClick={() => {
										onClose();
									}}>Ingen</button></div>
							</div>
						</Card.Body>
					</Card>
				);
			},
			overlayClassName: "overlay-custom"
		};
		confirmAlert(options);
	}

	render() {
		let _this = this;
		let detoxHeader = [];
		let detoxBody = [];
		let detoxDosageHeader = [];
		let detoxDosageBody = [];
		// let medicine_type=this.state.detox.medicine_type?this.state.detox.medicine_type:"";
		// let medicine_size=this.state.detox.medicine_size?this.state.detox.medicine_size:0;
		let strength = this.state.detox.medicine_strength ? this.state.detox.medicine_strength : 0;
		let unit = this.state.detox.medicine_unit ? this.state.detox.medicine_unit : "mg";
		let first_slot = "";
		if (this.state.header) {
			detoxDosageHeader.push(<th key={"slot-day"}>
				Day
			</th>);
			detoxDosageHeader.push(<th key={"slot-time"}>
				Date
			</th>);
			if (this.state.detox && this.state.detox.slot_dosages && Object.keys(this.state.detox.slot_dosages).length > 0) {
				let slotTime = "";
				Object.keys(this.state.detox.slot_dosages).map(item => {
					this.state.timeSlots.filter((slot) => {
						if (slot.id == item) {
							slotTime = slot.slot;
							return slot.slot;
						}
					});
					let checked = parseInt(item) === parseInt(_this.state.detox.first_slot_id) ? true : false;
					if (checked === true)
						first_slot = slotTime;

					detoxHeader.push(<th key={"slot-header-" + item} className="validi-radio-btnbox">
						<div>
							<input type="radio" name="detoxSlot[]" value={item} onChange={this.updateDetoxPlan} checked={checked} disabled={true} />
							<span className="checkmark"></span>
							<label>&nbsp;{slotTime}</label>
						</div>
					</th>);

					detoxDosageHeader.push(<th key={"slot-time-" + item}>
						<div>
							<label>{slotTime}</label>
						</div>
					</th>);
				});
			}
			let reductions = this.state.dosages;
			for (const key in reductions) {
				if (Object.hasOwnProperty.call(reductions, key)) {
					let reduction = reductions[key];
					let td = [];
					let dosageTd = [];
					td.push(<td key={"detox-dosage-" + key}>{key}</td>);
					let dayName = moment(key, "YYYY-MM-DD").format("dddd").toLowerCase();
					dosageTd.push(<td key={"dosage-day" + key}><Translate id={"medicine." + dayName} /></td>);
					dosageTd.push(<td key={"dosage-date" + key}>{key}</td>);
					for (const slotId in reduction) {
						if (Object.hasOwnProperty.call(reduction, slotId)) {
							let dosageResult = {};
							let defaultCheckedStatus = false;
							this.state.dbDosages.filter((dbDosage) => {
								if (dbDosage.slot_id == slotId && key == dbDosage.dosage_date) {
									dosageResult = dbDosage;
									defaultCheckedStatus = true;
									return dbDosage;
								}
							});
							var dosage = reduction[slotId] && reduction[slotId]['dosage_amt'] ? parseFloat(reduction[slotId]['dosage_amt']) : 0;
							var dosageStatus = parseFloat(dosageResult.dosage_status);
							td.push(<td key={"dosage-" + key + "-" + slotId} className="validi-mg-text">
								<input
									type="checkbox"
									name="dosage[]"
									value={dosage}
									defaultChecked={defaultCheckedStatus}
									disabled={true} />
								<span className="checkmark"></span>
								<label>
									&nbsp;{dosage.toFixed(1)} {webConst.shortType(this.state.detox.medicine_type)}  ({dosage.toFixed(1) * strength} {this.state.detox.medicine_unit})
								</label>
							</td>);
							if (dosageStatus === 1) {
								dosageTd.push(<td key={"dosage-data-" + key + "-" + slotId}>
									<div>{dosage.toFixed(1)} {webConst.shortType(this.state.detox.medicine_type)} ({dosage.toFixed(1) * strength} {this.state.detox.medicine_unit}) <img src={tickIcon} height="20px" /> {dosageResult.staff_initials} {dosageResult.dipensed_time} </div>
								</td>);
							}
							else {
								dosageTd.push(<td key={"dosage-data-" + key + "-" + slotId} className="validi-mg-text">
									<div>
										{this.state.editDosage[dosageResult.dosage_id] && this.state.editDosage[dosageResult.dosage_id] === true ?
											<div>
												<input type="text" name="dosoage" value={this.state.isEditedDosage ? this.state.dosage : dosageResult.dosage_amount} className="dosage-textbox" onChange={(e) => { this.setState({ "dosage": e.target.value }) }} />
												<button onClick={() => { this.updateDosage(dosageResult.dosage_id, _this.state.dosage) }} className="btn btn-sm btn-link"><Translate id="general.save" /></button>
											</div>
											:
											<span onClick={() => this.editDosageRecord(key,slotId,dosage,0)} title="Edit">{dosage.toFixed(1)} {webConst.shortType(this.state.detox.medicine_type)}
												&nbsp;({dosage.toFixed(1) * strength} {unit.replace(/[^a-zA-Z\/]+/g, '')})</span>
										}
									</div>
								</td>);
							}

						}
					}
					let tr = <tr key={"dosage-row-" + key}>{td}</tr>;

					detoxBody.push(tr);

					tr = <tr key={"detox-dosage-row-" + key}>{dosageTd}</tr>;
					detoxDosageBody.push(tr);
				}
			}
		}
		return (
			<div className="validi-main-layout validi-add-detox-layout">
				<div>
					<div className="container">
						<div className="validi-back-btn-wrapper">
							<Link to={routes.DETOX_PLAN_ROUTE}>
								<i><img src={backArrow} alt="back-arrow" /></i>
								<Translate id="medicine.back-to-detox" />
							</Link>						</div>
						<h1 className="main-title"><Translate id="medicine.view-detox-plan" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="suspended-image">
								{this.state.detox && parseInt(this.state.detox.status) === 1 ?
									<img src={suspendImage} alt="suspend image" />
									: ""
								}
							</div>
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-details">

									</div>
								</div>
								<div>
									{this.state.detox && parseInt(this.state.detox.status) !== 1 ?
										<div>
											<button className="validi-theme-btn clear-btn" onClick={() => { this.suspendDetox(0) }}><Translate id="medicine.suspend-detox-plan" /></button>
											<button className="validi-theme-btn clear-btn" onClick={() => { this.suspendDetox(1) }}><Translate id="medicine.suspend-and-copy-detox-plan" /></button>
										</div>
										: ""
									}
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="validi-table-data-wrapper">
										{
											this.state.detox ?
												<Table className="table" responsive="md">
													<tbody>
														<tr>
															<th><Translate id="medicine.detox-id" /></th>
															<td className="validi-date-text">{this.state.detox.id}</td>
														</tr>
														<tr>
															<th><Translate id="medicine.doctor" /></th>
															<td className="validi-date-text">{this.state.detox.doctor && this.state.detox.doctor.name ? webConst.ucfirst(this.state.detox.doctor.name) : ""}</td>
														</tr>
														<tr>
															<th><Translate id="medicine.medicine" /></th>
															<td className="validi-date-text">{this.state.detox.medicine && this.state.detox.medicine.name ? this.state.detox.medicine.name : ""}</td>
														</tr>
														<tr>
															<th><Translate id="medicine.medicine-type" /></th>
															<td className="validi-date-text">{this.state.detox.medicine_type ? webConst.ucfirst(this.state.detox.medicine_type) : ""}</td>
														</tr>
														<tr>
															<th><Translate id="medicine.strength" /></th>
															<td className="validi-date-text">{this.state.detox.medicine_size}</td>
														</tr>
														<tr>
															<th><Translate id="medicine.initial-dosage" /></th>
															<td className="validi-date-text">{this.state.detox.medicine_qty} {this.state.detox.medicine_type ? webConst.shortType(this.state.detox.medicine_type) : ""}</td>
														</tr>
														<tr>
															<th><Translate id="medicine.total" /></th>
															{this.state.detox.medicine_qty * this.state.detox.medicine_strength ?
																<td className="validi-date-text">{this.state.detox.medicine_qty} {webConst.shortType(this.state.detox.medicine_type)} * {this.state.detox.medicine_size} = {this.state.detox.medicine_qty * this.state.detox.medicine_strength} {this.state.detox.medicine_unit}</td>
																:
																<td className="validi-date-text">0 {this.state.detox.medicine_unit}</td>
															}
														</tr>
														{this.state.detox.medicine_image ?
															<tr>
																<td colSpan={2}><img src={webConst.getMedicineUrl(this.state.detox.medicine_image)} height={180} /></td>
															</tr>
															: ""
														}
													</tbody>
												</Table>
												: ""
										}
									</div>
								</div>
								<div className="col-md-6">
									<div className="validi-table-data-wrapper">
										<Table className="table" responsive="md">
											<tbody>
												<tr>
													<th><Translate id="medicine.start-date" /></th>
													<td className="validi-date-text">{this.state.detox.start_date !== "" ? moment(this.state.detox.start_date).format("DD-MM-YYYY") : ""}</td>
												</tr>
												<tr>
													<th><Translate id="medicine.first-dosage-date" /></th>
													<td className="validi-date-text">{this.state.detox.first_dosage_date !== "" ? moment(this.state.detox.first_dosage_date).format("DD-MM-YYYY") : ""}</td>
												</tr>
												<tr>
													<th><Translate id="medicine.end-date" /></th>
													<td className="validi-date-text">{this.state.detox.detox_finish_date !== "" ? moment(this.state.detox.detox_finish_date).format("DD-MM-YYYY") : ""}</td>
												</tr>
												<tr>
													<th><Translate id="medicine.start-time" /></th>
													<td className="validi-date-text">{this.state.detox.first_slot_id ? first_slot : ""}</td>
												</tr>
												<tr>
													<th><Translate id="medicine.slot-timing-for-medicine-plan" /></th>
													<td className="validi-date-text">
														{
															this.state.timeSlots ?
																this.state.timeSlots.map((item) => {
																	if (_this.state.detox.slot_dosages[item.id])
																		return <div key={"slot-" + item.id}>{moment(item.slot, "HH:mm").format("HH:mm")} : {_this.state.detox.slot_dosages[item.id]} {webConst.shortType(this.state.detox.medicine_type)} ({_this.state.detox.slot_dosages[item.id] * _this.state.detox.medicine_strength} {_this.state.detox.medicine_unit})</div>
																	else
																		return "";
																})
																: ""
														}
													</td>
												</tr>
												<tr>
													<th><Translate id="medicine.decimal-reduction" /></th>
													<td className="validi-date-text">{parseInt(this.state.detox.is_decimal_reduction) === 1 ? <Translate id="medicine.yes" /> : <Translate id="medicine.no" />}</td>
												</tr>
												<tr>
													<th><Translate id="medicine.until-suspended" /></th>
													<td className="validi-date-text">{parseInt(this.state.detox.is_infinate_dosage) === 1 ? <Translate id="medicine.yes" /> : <Translate id="medicine.no" />}</td>
												</tr>
												<tr className={parseInt(this.state.detox.is_infinate_dosage) === 1 ? "d-none" : ""}>
													<th><Translate id="medicine.next-reduction-dosage" /></th>
													<td className="validi-date-text">{this.state.detox.next_reduction_amount} {this.state.detox.medicine_unit}</td>
												</tr>
												<tr className={parseInt(this.state.detox.is_infinate_dosage) === 1 ? "d-none" : ""}>
													<th><Translate id="medicine.next-reduction-day" /></th>
													<td className="validi-date-text">{this.state.detox.next_reduction_days}</td>
												</tr>
												<tr>
													<th><Translate id="medicine.why-give-medcine" /></th>
													<td className="validi-date-text">{this.state.detox.remark}</td>
												</tr>
											</tbody>
										</Table>
									</div>
								</div>
								<div className="col-md-12">
									<div className="validi-table-data-wrapper">
										<div className="validi-reduction-plan-block">
											{this.state.dosages ?
												<div className="validi-reduction-plan-table">
													<div className="validi-tabbar-inner-header">
														<div className="">
															<h4 className="validi-subtitle"><Translate id="medicine.reduction" /></h4>
														</div>
													</div>
													<div className="validi-table-data-wrapper">
														<Table className="table" responsive="md">
															{
																detoxHeader ?

																	<thead>
																		<tr>
																			<th><Translate id="medicine.reduction-date" /></th>
																			{detoxHeader}
																		</tr>
																	</thead>
																	: ""
															}
															{
																detoxBody ?
																	<tbody>
																		{detoxBody}
																	</tbody>
																	: ""
															}
														</Table>
														<Table responsive="md">
															{detoxDosageHeader ?
																<thead>
																	<tr>
																		{detoxDosageHeader}
																	</tr>
																</thead>
																: ""
															}
															{
																detoxDosageBody ?
																	<tbody>
																		{detoxDosageBody}
																	</tbody>
																	: ""
															}
														</Table>
													</div>
												</div>
												: ""
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

DetoxDetail.defaultProps = {
	clientId: '',
	detoxSuccess: "",
	detoxFail: "",
	detoxStatus: "",
	detoxType: "",
	detoxId: ""
};

DetoxDetail.propTypes = {
	clientId: PropTypes.any,
	detoxSuccess: PropTypes.any,
	detoxFail: PropTypes.any,
	detoxStatus: PropTypes.any,
	detoxType: PropTypes.any,
	detoxId: PropTypes.any
};

function mapStateToProps(state) {
	return {
		detoxSuccess: state.detoxPlan.success,
		detoxFail: state.detoxPlan.fail,
		detoxStatus: state.detoxPlan.status,
		detoxType: state.detoxPlan.type,
		detoxId: state.detoxPlan.detoxId,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : ""
	};
}

const mapDispatchToProps = {
	getDetoxPlanDetailRequest,
	updateDetoxDosageRequest,
	detoxSuspendRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(DetoxDetail);