import { REGISTER_SUCCESS, REGISTER_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function registerReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };;
      case REGISTER_FAIL:
        return {
          ...state,
          success: '',
          fail: action.error,
          status: action.status,
          type: action.type
        };
      default:
        return state;
  }
}