import React, { Component, Fragment } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './Detox.scss';
import { Link } from 'react-router-dom';
import * as webConst from '../../../utils/WebConstant';
import * as routes from '../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import backArrow from '../../../images/back-arrow.svg';
import { getDetoxPlanRequest, getDispenseDetoxDosageRequest, dispenseDosageStatusRequest, setDetoxActiveTab } from '../../../containers/Medicines/DetoxPlanContainer/action';
import moment from "moment";
import DetoxDispenseHistory from './DetoxDispenseHistory';
import DetoxSuspended from './DetoxSuspended/DetoxSuspended';
import { DETOX_PLAN_SUCCESS } from '../../../containers/Medicines/DetoxPlanContainer/Constants';
import { Cube } from 'react-preloaders2';
import InfiniteScroll from 'react-infinite-scroll-component';
import Table from 'react-bootstrap/Table';
import EditIcon from '../../../images/edit-icon.svg';
import DeleteIcon from '../../../images/delete-icon.svg';
import viewIcon from '../../../images/view-icon.svg';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

class Detox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDetoxLoading: false,
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			activeDetoxTotalRecord: 0,
			activeDetoxRecords: [],
			detoxActiveTab: "detox-suspended",
			isLoading: false,
			pageSize: 20,
			'pageNumber': 0,
			detoxes: []
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo, isDetoxLoading: true, pageNumber: 0 });
			this.getActiveDetox();
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { detoxSuccess, detoxStatus, detoxType, detoxActiveTab } = this.props;
		if (this.state.detoxActiveTab !== detoxActiveTab && detoxActiveTab !== "") {
			if (detoxActiveTab !== "")
				this.setState({ detoxActiveTab: detoxActiveTab });
		}
		if (detoxStatus === 200) {
			if (this.state.isDetoxLoading) {
				this.setState({
					isDetoxLoading: false,
					isLoading: false
				});
			}

			if (prevProps.detoxSuccess !== detoxSuccess) {
				if (detoxType === DETOX_PLAN_SUCCESS) {
					let total = this.props.detoxSuccess.total;
					this.setState({ detoxes: [...this.state.detoxes, ...detoxSuccess.detoxes] });
					this.setState({
						totalRecord: total,
						isMoreRecord: true,
						isLoading: false,
						isDetoxLoading: false,
						paged: parseInt(this.state.paged) + 1
					});
				}
			}
		}

		if (detoxStatus === 400) {
			if (this.state.isDetoxLoading) {
				this.setState({
					isDetoxLoading: false,
					isLoading: false
				});
			}
			if (this.state.activeDetoxRecords.length > 0)
				this.setState({ activeDetoxRecords: [], activeDetoxTotalRecord: 0 });
		}
	}
	editRow = (detox) => {
		this.props.history.push(routes.ADD_DETOX_ROUTE + "/" + detox.id);
	}

	deleteRow = (slot) => {
		confirmAlert({
			title: "Delete time slot",
			message: "Are you sure delete this time slot?",
			buttons: [
				{
					label: "Ja",
					onClick: () => {
						if (slot.id) {
							this.setState({ 'isLoading': true });
							this.props.deleteTimeSlotRequest(slot.id);
						}
					}
				},
				{
					label: "Ingen",
					// onClick: () => alert('Click No')
				}
			],
		});
	}

	viewDetox = (record) => {
		this.props.history.push(routes.DETOX_DETAIL_ROUTE + "/" + record.id);
	}

	getActiveDetox() {
		let pageNumber = this.state.pageNumber ? this.state.pageNumber : 0;
		pageNumber = pageNumber + 1;
		if ((this.state.totalRecord > this.state.detoxes.length) || pageNumber == 1) {
			if (!this.state.isDetoxLoading) {
				this.setState({ 'isDetoxLoading': true, 'isLoading': true });
			}
			const params = new URLSearchParams();
			params.append('client_id', webConst.getClientId());
			params.append('page', pageNumber);
			params.append('limit', this.state.pageSize);
			this.props.getDetoxPlanRequest(params);
			this.setState({ pageNumber: pageNumber });
		}
	}

	activeTab = (id) => {
		if (id == 'add-detox') {
			this.props.history.push(routes.ADD_DETOX_ROUTE);
			this.props.setDetoxActiveTab("active-detox");
		}
		else if (id == 'detox-dispense') {
			this.props.history.push(routes.DETOX_DISPENSE_ROUTE);
			this.props.setDetoxActiveTab("detox-dispense");
		}
		else {
			this.props.history.push(routes.DETOX_PLAN_ROUTE);
			this.props.setDetoxActiveTab(id);
		}
	}

	render() {
		return (
			<div className="validi-main-layout validi-detox-medicine-layout">
				<div>
					<div className="container">
						<div className="validi-back-btn-wrapper">
							<Link to={routes.MEDICINE_PLAN_ROUTE}>
								<i>
									<img src={backArrow} />
								</i>
								<Translate id="medicine.back-to-medicine-plan" />
							</Link>
						</div>
						<h1 className="main-title"><Translate id="medicine.detox-plans" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
								<div className="validi-select-input">
								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example" defaultActiveKey={this.state.detoxActiveTab} activeKey={this.state.detoxActiveTab} onSelect={(id) => { this.activeTab(id) }}>
									<Tab eventKey="active-detox" title={<Translate id="medicine.detox-plans" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="medicine.detox-plans" /></h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<div className='table-responsive-md'>
													<InfiniteScroll
														dataLength={this.state.detoxes && this.state.detoxes.length ? this.state.detoxes.length : 0}
														next={() => this.getActiveDetox}
														hasMore={this.state.isMoreRecord}
														loader={this.state.isDetoxLoading ? <h4><Translate id="general.loading" /></h4> : ""}
													>
														{this.state.detoxes && Object.keys(this.state.detoxes).length ?
															<Table responsive="md">
																<thead>
																	<tr>
																		<th>#</th>
																		<th><Translate id="general.doctor" /></th>
																		<th><Translate id="general.medicine" /></th>
																		<th><Translate id="general.start-date" /></th>
																		<th><Translate id="general.end-date" /></th>
																		<th><Translate id="general.total" /></th>
																		<th><Translate id="general.created-by" /></th>
																		<th><Translate id="general.note" /></th>
																		<th><Translate id="general.action" /></th>
																	</tr>
																</thead>
																<tbody>
																	{this.state.detoxes.map((detox, index) => (
																		<tr key={index}>
																			<td className="validi-date-text">{index + 1}</td>
																			<td className='text-capitalize'>{detox.doctor.name}</td>
																			<td className='text-capitalize'>{detox.medicine.name}</td>
																			<td>{detox.start_date}</td>
																			<td>{detox.end_date}</td>
																			<td>{detox.initial_amount} {webConst.shortType(detox.medicine_package.medicine_type)}</td>
																			<td className='text-capitalize'>{detox.user.name}</td>
																			<td>{detox.remark}</td>
																			<td className='text-right'>
																				<div className="validi-active-icons-wrapper">
																					<i onClick={() => this.editRow(detox)}><img src={EditIcon} alt="edit row" /></i>
																					{/* <i onClick={() => this.deleteRow(detox)}><img src={DeleteIcon} alt="delete row" /></i> */}
																					<i onClick={() => this.viewDetox(detox)}><img src={viewIcon} alt="view detox" /></i>
																				</div>
																			</td>
																		</tr>
																	))}
																</tbody>
															</Table>
															: ""
														}
													</InfiniteScroll>
													{
														this.state.detoxes && this.state.detoxes.length === 0 ?
															<div className='alert alert-danger'>
																<Translate id="medicine.detox-plan"></Translate> <Translate id="alert-message.not-found"></Translate>
															</div>
															: ""
													}
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add-detox" title={<Translate id="medicine.add-detox-plan" />}>
										Add detox plan
									</Tab>
									<Tab eventKey="detox-dispense" title={<Translate id="medicine.detox-dispenses" />}>
										Detox dispense
									</Tab>
									<Tab eventKey="detox-dispense-history" title={<Translate id="medicine.detox-dispense-histories" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle">Detox dispense histories</h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<DetoxDispenseHistory />
											</div>
										</div>
									</Tab>
									<Tab eventKey="detox-suspended" title={<Translate id="medicine.suspended-detox" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle">Suspended Detox</h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<DetoxSuspended history={this.props.history} />
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</div>
						<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		)
	}
}

Detox.defaultProps = {
	clientId: '',
	detoxSuccess: "",
	detoxFail: "",
	detoxStatus: "",
	detoxActiveTab: "active-detox"
};

Detox.propTypes = {
	clientId: PropTypes.any,
	detoxSuccess: PropTypes.any,
	detoxFail: PropTypes.any,
	detoxStatus: PropTypes.any,
	detoxType: PropTypes.any,
	detoxActiveTab: PropTypes.any
};

function mapStateToProps(state) {
	return {
		detoxSuccess: state.detoxPlan.success,
		detoxFail: state.detoxPlan.fail,
		detoxStatus: state.detoxPlan.status,
		detoxType: state.detoxPlan.type,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		detoxActiveTab: state.detoxPlan.detoxActiveTab
	};
}

const mapDispatchToProps = {
	getDetoxPlanRequest,
	getDispenseDetoxDosageRequest,
	dispenseDosageStatusRequest,
	setDetoxActiveTab
};

export default connect(mapStateToProps, mapDispatchToProps)(Detox);
