import {
  DETOX_PLAN_SUCCESS,
  DETOX_PLAN_FAIL,
  DETOX_PLAN_DETAIL_SUCCESS,
  DETOX_PLAN_DETAIL_FAIL,
  UPDATE_DETOX_DOSAGE_SUCCESS,
  UPDATE_DETOX_DOSAGE_DETAIL_FAIL,
  GET_DISPENSE_DOSAGE_SUCCESS,
  GET_DISPENSE_DOSAGE_FAIL,
  DISPENSE_DOSAGE_STATUS_SUCCESS,
  DISPENSE_DOSAGE_STATUS_FAIL,
  DETOX_DISPENSE_HISTORY_SUCCESS,
  DETOX_DISPENSE_HISTORY_FAIL,
  DETOX_SUSPENDED_HISTORY_SUCCESS,
  DETOX_SUSPENDED_HISTORY_FAIL,
  DETOX_SUSPEND_SUCCESS,
  DETOX_SUSPEND_FAIL,
  DETOX_ACTIVE_TAB,
  DETOX_ID,
  ADD_DETOX_PLAN_SUCCESS,
  ADD_DETOX_PLAN_FAIL,
  UPDATE_DETOX_PLAN_SUCCESS,
  UPDATE_DETOX_PLAN_FAIL
} from './Constants';

const initialState = {
  isLoading: true,
};

export default function addMedicineReducer(state = initialState, action) {
  switch (action.type) {
    case DETOX_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case DETOX_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DETOX_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case DETOX_PLAN_DETAIL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case UPDATE_DETOX_DOSAGE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case UPDATE_DETOX_DOSAGE_DETAIL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case GET_DISPENSE_DOSAGE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case GET_DISPENSE_DOSAGE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DISPENSE_DOSAGE_STATUS_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DISPENSE_DOSAGE_STATUS_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DETOX_DISPENSE_HISTORY_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DETOX_DISPENSE_HISTORY_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DETOX_SUSPENDED_HISTORY_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DETOX_SUSPENDED_HISTORY_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DETOX_SUSPEND_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DETOX_SUSPEND_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case DETOX_ACTIVE_TAB:
      return {
        ...state,
        detoxActiveTab: action.detoxType,
        type: action.type
      };
    case DETOX_ID:
      return {
        ...state,
        detoxId: action.detoxId,
        type: action.type
      };
    case ADD_DETOX_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ADD_DETOX_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case UPDATE_DETOX_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case UPDATE_DETOX_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    default:
      return state;
  }
}