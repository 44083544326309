import React, { Component } from 'react';
import './Login.scss';
import { connect } from 'react-redux';
import { loginRequest } from '../../../containers/Auth/LoginContainer/action';
import * as webConst from '../../../utils/WebConstant';
import { withHeader } from '../../../service/ApiConfig';
import { Translate } from 'react-localize-redux';
import { Form, Button } from 'react-bootstrap';
// import logo from '../../../images/logo.png';
import * as routes from '../../../utils/RouteConstant';
import { NavLink } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);

    // set the state of the app
    this.state = {
      form: {
        email: '',
        password: '',
        remember: false,
      },
      error: '',
      isLoading: false,
      isValidated: false
    }
    // bind component with event handlers
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // event to handle input change
  handleChange = (name, value) => {
    this.setState({ 'error': '' });
    this.setState({ form: { ...this.state.form, [name]: value } });
  }

  // event to handle form submit
  async handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ "isValidated": false });
    } else {
      const { form } = this.state;
      const params = new URLSearchParams();
      params.append('email', form.email);
      params.append('password', form.password);
      this.props.loginRequest(params);
      this.setState({ 'isLoading': true });
    }
    this.setState({ "isValidated": true });
  }

  showError(fieldname) {
    return this.state.formError && this.state.formError && this.state.formError[fieldname] ? <span className="text-danger">{this.state.formError[fieldname]}</span> : '';
  }

  componentDidUpdate(prevProps) {
    const { loginSuccess, loginFail, loginStatus } = this.props;
    if (loginStatus === 200) {

      if (prevProps.loginSuccess !== this.props.loginSuccess) {
        this.setState({
          formError: '',
          logSuccess: loginSuccess,
        }, () => {
          this.afterSuccessLogin(loginSuccess);
        });
        this.forceUpdate();
      }
      if (this.state.isValidated === false) {
        this.setState({
          isLoading: false,
          isValidated: true
        });
      }
    }
    else {
      if (this.state.isLoading) {
        this.setState({
          logSuccess: '',
          formError: loginFail,
          isLoading: false
        });
      }

      if (prevProps.loginFail !== loginFail) {
        if (loginStatus == 401)
          webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.login-fail" />);
        else
          webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
      }
    }

    if (this.state.isValidated === false) {
      this.setState({
        isLoading: false,
        isValidated: true
      });
    }
  }

  afterSuccessLogin(objData) {
    webConst.setUser(objData);
    webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
    setTimeout(() => {
      withHeader.headers.Authorization = webConst.getUserToken();
    }, 500);
    let _this = this;
    setTimeout(() => {
      window.location.href=routes.HOME_ROUTE;
      // _this.props.history.push("/home");
    }, 500);
  }

  render() {
    return (
      <div className="validi-login-layout-main">
        <div className="validi-login-form-wrapper">
          <div className="validi-logo-block">
            <img src="/images/logo.png" alt="" />
            <h3 className="main-title">{process.env.REACT_APP_NAME}</h3>
          </div>
          <Form noValidate validated={this.state.isValidated} onSubmit={this.handleSubmit}>
            <Form.Group className="form-group" controlId={"email"}>
              <Form.Label><Translate id="login.email" /></Form.Label>
              <Translate>
                {({ translate }) =>
                  <Form.Control
                    type="text"
                    name="email"
                    className="form-control"
                    value={this.state.form.email ? this.state.form.email : ''}
                    onChange={(e) => { this.handleChange(e.target.name, e.target.value) }}
                    placeholder={translate("login.email")}
                    required
                  />
                }
              </Translate>
              <Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" controlId={"password"}>
              <Form.Label><Translate id="login.password" /></Form.Label>
              <Translate>
                {({ translate }) =>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={(e) => { this.handleChange(e.target.name, e.target.value) }}
                    value={this.state.form.password ? this.state.form.password : ''}
                    placeholder={translate("login.password")}
                    required
                  />
                }
              </Translate>
              <Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
            </Form.Group>
            <NavLink to={routes.FORGOT_PASSWORD_ROUTE} className="forgot-link"><Translate id="login.forgot-password"></Translate></NavLink>
            <button type="submit" className="validi-theme-btn">
              {webConst.isLoader(this.state.isLoading, <Translate id="login.title" />)}
            </button>
          </Form>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loginSuccess: state.login.success,
    loginFail: state.login.fail,
    loginStatus: state.login.status,
    loggedIn: state.login.loggedIn
  };
}

const mapDispatchToProps = { loginRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Login);