import React, { Component, Fragment } from 'react';
// import Select from "react-select";
import { Tabs, Tab, Table, FormGroup, Label, Input, Popover, OverlayTrigger } from 'react-bootstrap';
import './DetoxDispenseHistory.scss';
import { Link } from 'react-router-dom';
import * as webConst from '../../../../utils/WebConstant';
import * as routes from '../../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { getDetoxDispenseHistoryRequest, setDetoxActiveTab } from '../../../../containers/Medicines/DetoxPlanContainer/action';
import { DETOX_DISPENSE_HISTORY_SUCCESS } from '../../../../containers/Medicines/DetoxPlanContainer/Constants';
import { propTypes } from 'react-bootstrap/esm/Image';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Cube } from 'react-preloaders2';
class DetoxDispenseHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDosageLoading: false,
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			activeDetoxTotalRecord: 0,
			activeDetoxRecords: [],
			isLoading: false,
			pageSize: 20,
			'pageNumber': 0,
			dosages: [],
			detoxActiveTab: ""
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { detoxSuccess, detoxStatus, detoxType, detoxActiveTab } = this.props;
		if (this.state.detoxActiveTab != detoxActiveTab) {
			this.setState({ detoxActiveTab: detoxActiveTab });
			if (detoxActiveTab === "detox-dispense-history") {
				let params = new URLSearchParams();
				params.append('client_id', webConst.getClientId());
				params.append('user_id', webConst.getUserId());
				this.getDetoxDispenseHistory(params);
				this.setState({ detoxActiveTab: detoxActiveTab, isDosageLoading: true });
			}
		}

		if (detoxStatus === 200) {
			if (this.state.isDosageLoading) {
				this.setState({
					isDosageLoading: false,
				});
			}
			if (detoxType == DETOX_DISPENSE_HISTORY_SUCCESS) {
				if (prevProps.detoxSuccess !== detoxSuccess) {
					let total = this.props.detoxSuccess.total;
					this.setState({ dosages: [...this.state.dosages, ...detoxSuccess.dosages] });
					this.setState({
						totalRecord: total,
						isMoreRecord: true,
						isLoading: false,
						isDosageLoading: false,
						paged: parseInt(this.state.paged) + 1
					});
				}
			}
		}

		if (detoxStatus === 400) {
			if (this.state.isDosageLoading) {
				this.setState({
					isDosageLoading: false,
				});
			}
			if (this.state.dosages.length > 0) {
				this.setState({ dosages: [], totalRecord: 0 });
			}
		}
	}

	getDetoxDispenseHistory() {
		let pageNumber = this.state.pageNumber ? this.state.pageNumber : 0;
		pageNumber = pageNumber + 1;
		if ((this.state.totalRecord > this.state.dosages.length) || pageNumber == 1) {
			if (!this.state.isDosageLoading) {
				this.setState({ 'isDosageLoading': true, 'isLoading': true });
			}
			const params = new URLSearchParams();
			params.append('client_id', webConst.getClientId());
			params.append('page', pageNumber);
			params.append('limit', this.state.pageSize);
			this.props.getDetoxDispenseHistoryRequest(params);
			this.setState({ pageNumber: pageNumber });
		}
	}

	editRow = (detox) => {
		this.props.history.push(routes.ADD_DETOX_ROUTE + "/" + detox.id);
	}

	viewDetox = (record) => {
		this.props.history.push(routes.DETOX_DETAIL_ROUTE + "/" + record.id);
	}


	render() {
		return (
			<div>
				<InfiniteScroll
					dataLength={this.state.detoxes && this.state.detoxes.length ? this.state.detoxes.length : 0}
					next={() => this.getActiveDetox}
					hasMore={this.state.isMoreRecord}
					loader={this.state.isDosageLoading ? <h4><Translate id="general.loading" /></h4> : ""}
				>
					{this.state.dosages && Object.keys(this.state.dosages).length ?
						<Table responsive="md">
							<thead>
								<tr>
									<th>#</th>
									<th><Translate id="medicine.dosage-date" /></th>
									<th><Translate id="medicine.dispense-date" /></th>
									<th><Translate id="medicine.time" /></th>
									<th><Translate id="medicine.medicine" /></th>
									<th><Translate id="medicine.dosage-amount" /></th>
									<th><Translate id="medicine.dispense-by" /></th>
									<th><Translate id="general.note" /></th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.dosages.map((dosage, index) => (
										<tr key={index}>
											<td className="validi-date-text">{index + 1}</td>
											<td className='text-capitalize'>{dosage.dosage_date}</td>
											<td className='text-capitalize'>{dosage.created_at}</td>
											<td>{dosage.timeslot.slot}</td>
											<td>{dosage.medicine.name}</td>
											<td>{dosage.dosage_amount} {webConst.shortType(dosage.medicine_type)} * {dosage.medicine_package.unit} = {parseFloat(dosage.medicine_package.unit_strength / 1000) * dosage.dosage_amount} {dosage.medicine_package.unit.replace(/[^a-zA-Z\//-]+/g, '')}</td>
											<td className='text-capitalize'>{dosage.user.name}</td>
											<td className='text-capitalize'>{dosage.description}</td>
										</tr>
									))
								}
							</tbody>
						</Table>
						:""
					}
				</InfiniteScroll>
				{
					this.state.dosages && this.state.dosages.length === 0 ?
						<div className='alert alert-danger'>
							<Translate id="medicine.detox-dispenses"></Translate> <Translate id="alert-message.not-found"></Translate>
						</div>
						: ""
				}
				<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
			</div>
		)
	}
}

DetoxDispenseHistory.defaultProps = {
	clientId: '',
	detoxSuccess: "",
	detoxFail: "",
	detoxStatus: "",
	detoxType: "",
	detoxActiveTab: ""
};

DetoxDispenseHistory.propTypes = {
	clientId: PropTypes.any,
	detoxSuccess: PropTypes.any,
	detoxFail: PropTypes.any,
	detoxStatus: PropTypes.any,
	detoxType: PropTypes.any,
	detoxActiveTab: PropTypes.any
};

function mapStateToProps(state) {
	return {
		detoxSuccess: state.detoxPlan.success,
		detoxFail: state.detoxPlan.fail,
		detoxStatus: state.detoxPlan.status,
		detoxType: state.detoxPlan.type,
		// detoxId: state.detoxPlan.detoxId,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		detoxActiveTab: state.detoxPlan.detoxActiveTab
	};
}

const mapDispatchToProps = {
	getDetoxDispenseHistoryRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(DetoxDispenseHistory);
