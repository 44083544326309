import React, { Component, Fragment } from 'react';
import './User.scss';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { registerRequest } from '../../containers/Auth/RegisterContainer/action';
import { getUserListRequest, getUserTooltipRequest } from '../../containers/UserContainer/action';
import * as routes from '../../utils/RouteConstant';
import * as webConst from '../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Cube } from 'react-preloaders2';
import Table from 'react-bootstrap/Table';
import { OverlayTrigger, Overlay, Tooltip, Button, Popover, Modal, Tabs, Tab, Form } from 'react-bootstrap';
import { USER_SUCCESS, USER_TOOLTIP_SUCCESS } from '../../containers/UserContainer/Constants';
import { REGISTER_SUCCESS } from '../../containers/Auth/RegisterContainer/Constants';
import { deleteUserRequest } from '../../containers/UserContainer/action';
import { USER_DELETE_SUCCESS } from '../../containers/UserContainer/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import daLocale from "date-fns/locale/da";
import defaultPhoto from '../../images/default_avatar.png';
import DeleteIcon from '../../images/delete-icon.svg';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

class User extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			defaultPhoto: defaultPhoto,
			totalRecord: 0,
			isDatatableLoading: true,
			tooltips: {},
			isModal: false,
			pageSize: 20,
			pageNumber: 0,
			isMoreRecord: true,
			staffs: [],
			validated: false,
			name: "",
			email: "",
			phone: "",
			clientBirthDate: "",
			username: "",
			password: "",
			initials: "",
			activeKey: "staffs",
			staffType: 0,
			isFormLoading: false,
			staffName: "",
			password: ""
		};

		this.popoverRef = React.createRef();
		this.popoverClientRef = React.createRef();
		this.staffForm = React.createRef();
		this.clearstaffForm = this.clearstaffForm.bind(this);
	}


	componentDidMount() {
		this.getUser();
	}

	componentDidUpdate(prevProps) {
		const { registerSuccess, registerFail, registerStatus, registeType, userSuccess, userFail, userStatus, userType } = this.props;
		if (registerStatus === 200) {
			if (prevProps.registerSuccess !== registerSuccess) {
				let _this = this;
				if (this.state.isFormLoading) {
					this.setState({
						isDatatableLoading: true,
						isFormLoading: false,
						validated: false,
						pageNumber: 0,
						activeKey: "staffs",
						staffs: [],
						validated: true,
						name: "",
						email: "",
						phone: "",
						clientBirthDate: "",
						username: "",
						password: "",
						initials: "",
						password: ""
					});
				}
				this.staffForm.current.reset();
				this.staffForm.current.classList.remove("was-validated");
				setTimeout(() => {
					_this.getUser();
				}, 1000);
				webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="alert-message.client-save-message-alert" />);
			}
		}

		if (userStatus === 200) {
			if (prevProps.userSuccess !== userSuccess) {
				if (this.state.isDatatableLoading) {
					this.setState({
						isLoading: false,
						isDatatableLoading: false
					});
				}

				if (userType == USER_SUCCESS) {
					if (this.props.userSuccess && this.props.userSuccess.users) {
						let total = this.props.userSuccess.total;
						this.setState({ staffs: [...this.state.staffs, ...userSuccess.users] });
						this.setState({
							totalRecord: total,
							isMoreRecord: true
						});
					}
				}


				if (userType == USER_TOOLTIP_SUCCESS) {
					if (this.props.userSuccess) {
						this.setState({ tooltips: this.props.userSuccess });
					}
				}

				if (userType == USER_DELETE_SUCCESS) {
					let _this=this;
					this.setState({ pageNumber: 0,staffs:[] });
					webConst.toaster(webConst.TOAST_SUCCESS, <><Translate id="staff.title" />  <Translate id="alert-message.deleted-message" /></>);
					setTimeout(() => {
						_this.getUser();
					}, 500);
				}
			}
		}

		if (prevProps.registerFail !== registerFail) {
			if (this.state.isDatatableLoading) {
				this.setState({
					isLoading: false,
					isDatatableLoading: false,
					isMoreRecord: false
				});
			}
		}

		if (prevProps.userFail !== userFail) {
			if (this.state.isDatatableLoading) {
				this.setState({
					isLoading: false,
					isDatatableLoading: false,
					isMoreRecord: false
				});
			}
		}
	}

	getTooltipDetail = (item) => {
		this.setState({ className: webConst.ucfirst(item.name) });
		this.props.getUserTooltipRequest(item.id);
		this.setState({ tooltips: "" });
	}

	drawTooltip = () => {
		let data = [];
		let _this = this;
		//table data
		const tableData = (data) => {
			return <table className="table"><tbody>{data}</tbody></table>
		}
		// get table row data
		const tdData = (label, data) => {
			return <tr key={"tooltip-" + Math.random(1, 1000)}><th>{label}</th><td key={data}>{data}</td></tr>
		}

		if (this.state.tooltips) {
			data.push(tdData(<Translate id={"general.staff"} />, this.state.tooltips['name']));
			data.push(tdData(<Translate id={"general.username"} />, this.state.tooltips['username']));
			data.push(tdData(<Translate id={"general.email"} />, this.state.tooltips['email']));
			data.push(tdData(<Translate id={"general.phone"} />, this.state.tooltips['phone']));
			data.push(tdData(<Translate id={"general.birth-date"} />, this.state.tooltips['birth_date']));
			data.push(tdData("Staff", this.state.tooltips['user_type']));
			data.push(tdData(<Translate id={"staff.initials"} />, this.state.tooltips['initials']));
		}
		return data;
	}

	async getUser(event) {
		let pageNumber = this.state.pageNumber + 1;
		if ((this.state.totalRecord > this.state.staffs.length) || pageNumber == 1) {
			if (!this.state.isDatatableLoading) {
				this.setState({ 'isDatatableLoading': true, 'isLoading': true });
			}
			const params = new URLSearchParams();
			params.append('page', pageNumber);
			params.append('limit', this.state.pageSize);
			params.append('type', 'staff');
			this.props.getUserListRequest(params);
			this.setState({ pageNumber: pageNumber });
		}
	}

	closeModal = () => {
		this.setState({ isModal: false });
	}

	clientRow = (item) => {
		let _this = this;
		let data = "";
		if (this.state.tooltips && typeof this.state.tooltips != "undefined") {
			data = this.drawTooltip();
		}

		const clientDetailPopover = (
			<Popover id="popover-trigger-hover-focus">
				<Popover.Header as="h3">{webConst.ucfirst(item.name)}</Popover.Header>
				<Popover.Body>
					{(item.photo) ? <img src={item.photo} className='rounded' height={"100px"} /> : <img src={_this.state.defaultPhoto} className='rounded' height={"100px"} />}
					<Table className='table'>
						<tbody>
							{data}
						</tbody>
					</Table>
				</Popover.Body>
			</Popover>
		);
		return (
			<div className='row mt-2'>
				<div className='col-md-6 pl-2'>
					<div className="d-lg-none">
						<Link to="#" className='text-capitalize' onClick={e => { this.getTooltipDetail(item); this.setState({ isModal: true }) }} >{item.name}</Link>
					</div>
					<div className="d-none d-lg-block">
						<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={clientDetailPopover} delay={500} onEntered={() => { this.getTooltipDetail(item) }}>
							<Link to="#" className='text-capitalize'>{item.name}</Link>
						</OverlayTrigger>
					</div>
				</div>
			</div>
		);
	}

	staffSubmitHandler = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "validated": true });
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			const params = new URLSearchParams();
			params.append('name', event.target.name.value);
			params.append('email', event.target.email.value);
			params.append('phone', event.target.phone.value);
			params.append('birth_date', event.target.clientBirthDate.value);
			params.append('initials', event.target.initials.value);
			params.append('staff_type', event.target.staffType.value);
			params.append('register_type', 'staff');
			params.append('username', event.target.username.value);
			params.append('password', event.target.password.value);
			this.props.registerRequest(params);
			this.setState({ 'isFormLoading': true, validated: false });
		}
		this.setState({ "validated": true });
	}

	handleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		this.setState({ [name]: value });
	}

	handleChangeDateRange = (name, value) => {
		this.setState({ [name]: value });
	}

	setActiveKey = (key) => {
		this.setState({ "activeKey": key });
	}

	clearstaffForm = () => {
		this.staffForm.current.reset();
		this.setState({
			validated: true,
			name: "",
			email: "",
			phone: "",
			clientBirthDate: "",
			username: "",
			password: "",
			initials: "",
			password: ""
		});
		this.staffForm.current.classList.remove("was-validated");
	}

	deleteRow = (user) => {
		confirmAlert({
			title: "Delete User",
			message: "Are you sure delete this user?",
			buttons: [
				{
					label: "Ja",
					onClick: () => {
						if (user.id) {
							this.setState({ 'isLoading': true });
							this.props.deleteUserRequest(user.id);
						}
					}
				},
				{
					label: "Ingen",
					// onClick: () => alert('Click No')
				}
			],
		});
	}

	render() {
		return (
			<div className="validi-main-layout validi-detox-medicine-layout">
				<div>
					<div className="container">

						<h1 className="main-title"><Translate id="staff.title" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example" activeKey={this.state.activeKey} defaultActiveKey="staffs" onSelect={(id) => { this.setActiveKey(id) }}>
									{
										this.props.userSuccess && this.props.userSuccess.users ?
											<Tab eventKey="staffs" title={<Translate id="staff.title" />}>
												<div className="">
													<div className="validi-tabbar-inner-header">
														<div className="">
															<h4 className="validi-subtitle"><Translate id="staff.title" /></h4>
														</div>
													</div>
													<div className="validi-table-data-wrapper">
														<div className='table-responsive-md'>
															<InfiniteScroll
																dataLength={this.state.staffs && this.state.staffs.length ? this.state.staffs.length : 0}
																next={this.getClient}
																hasMore={this.state.isMoreRecord}
																loader={this.state.isDatatableLoading ? <Translate id='general.loading' /> : ""}
															>
																<Table responsive="md">
																	<thead>
																		<tr>
																			<th>#</th>
																			<th><Translate id="general.name" /></th>
																			<th><Translate id="general.email" /></th>
																			<th><Translate id="general.phone" /></th>
																			<th><Translate id="general.birth-date" /></th>
																			<th><Translate id="staff.initials" /></th>
																			<th><Translate id="staff.staff-type" /></th>
																			<th><Translate id="general.action" /></th>
																		</tr>
																	</thead>
																	<tbody>
																		{this.state.staffs.map((staff, index) => (
																			<tr key={index}>
																				<td className="validi-date-text">{index + 1}</td>
																				<td className='text-capitalize'>{staff.name}</td>
																				<td className='validi-date-text'>{staff.email}</td>
																				<td className='validi-date-text text-capitalize'>{staff.phone}</td>
																				<td className='validi-date-text text-capitalize'>{staff.birth_date}</td>
																				<td className='validi-date-text text-capitalize'>{staff.initials}</td>
																				<td className='validi-date-text text-capitalize'>{staff.user_type}</td>
																				<td className='text-right'>
																					<div className="validi-active-icons-wrapper">
																						<i onClick={() => this.deleteRow(staff)}><img src={DeleteIcon} alt="delete row" /></i>
																					</div>
																				</td>
																			</tr>
																		))}
																	</tbody>
																</Table>
															</InfiniteScroll>
															{
																this.props.userSuccess.users.length === 0 ?
																	<div className='alert alert-danger'>
																		<Translate id="staff.title"></Translate> <Translate id="alert-message.not-found"></Translate>
																	</div>
																	: ""
															}
														</div>
													</div>
												</div>
											</Tab>
											: ""
									}
									<Tab eventKey="add-staff" title={<Translate id="staff.add-staff" />}>
										<div className="validi-tabbar-inner-header">
											<h4 className="validi-subtitle"><Translate id="staff.add-staff" /></h4>
										</div>
										<div className="validi-journal-msg-wrapper">
											<div className="row">
												<div className="col-md-12">
													<Form noValidate validated={this.state.validated} onSubmit={this.staffSubmitHandler} ref={this.staffForm}>
														<Form.Group className="form-group" controlId="name">
															<Form.Label><Translate id="staff.staff-name" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Control
																		type="text"
																		name="name"
																		className="form-control"
																		value={this.state.name ? this.state.name : ''}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("staff.staff-name")}
																		required
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Group className="form-group" controlId="email">
															<Form.Label><Translate id="general.email" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Control
																		type="email"
																		name="email"
																		className="form-control"
																		value={this.state.email ? this.state.email : ''}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("general.email")}
																		required
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Group className="form-group" controlId="username">
															<Form.Label><Translate id="general.username" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Control
																		type="text"
																		name="username"
																		className="form-control"
																		value={this.state.username ? this.state.username : ''}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("general.username")}
																		required
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Group className="form-group" controlId="password">
															<Form.Label><Translate id="general.password" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Control
																		type="password"
																		name="password"
																		className="form-control"
																		value={this.state.password ? this.state.password : ''}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("general.password")}
																		required
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Group className="form-group" controlId="phone">
															<Form.Label><Translate id="general.phone" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Control
																		type="text"
																		name="phone"
																		className="form-control"
																		value={this.state.phone ? this.state.phone : ''}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("general.phone")}
																		required
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Group className="form-group" controlId="client_birth">
															<Form.Label><Translate id="general.client-birth" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<DatePicker
																		selected={this.state.clientBirthDate}
																		onChange={(date) => { this.handleChangeDateRange('clientBirthDate', date) }}
																		selectsStart
																		startDate={this.state.clientBirthDate}
																		dateFormat="dd-MM-yyyy"
																		className="form-control"
																		withPortal
																		locale={daLocale}
																		placeholderText={translate("general.client-birth")}
																		name={"clientBirthDate"}
																		required
																		autoComplete='off'
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Group className="form-group" controlId="initials">
															<Form.Label><Translate id="staff.initials" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Control
																		type="text"
																		name="initials"
																		className="form-control"
																		value={this.state.phone ? this.state.initials : ''}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("staff.initials")}
																		required
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Group className="form-group" controlId="staffType">
															<Form.Label><Translate id="general.select-staff-type" /></Form.Label>
															<div className="validi-radio-btnbox mt-2 mb-2">
																<Form.Check type={"radio"} id={`radio-staff`} className="radio-btn">
																	<Form.Check.Input type="radio" name="staffType" id="staff-user" checked={this.state.staffType === 0 ? true : false} value={0} onChange={(e) => { this.setState({ staffType: 0 }); }} />
																	<span className="checkmark"></span>
																	<Form.Check.Label htmlFor="staff-user"><Translate id="general.staff" /></Form.Check.Label>
																</Form.Check>
															</div>
															<div className="validi-radio-btnbox  mt-2 mb-2">
																<Form.Check type={"radio"} id={`radio-doctor`} className="radio-btn">
																	<Form.Check.Input type="radio" name="staffType" id="doctor-user" checked={this.state.staffType === 1 ? true : false} value={1} onChange={(e) => { this.setState({ staffType: 1 }); }} />
																	<span className="checkmark"></span>
																	<Form.Check.Label htmlFor='doctor-user'><Translate id="general.doctor" /></Form.Check.Label>
																</Form.Check>
															</div>
														</Form.Group>
														<div className="">
															<button className="validi-theme-btn">
																{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}
															</button>
															<button type="button" className="validi-theme-btn clear-btn" onClick={this.clearstaffForm}><Translate id="general.clear" /></button>
														</div>
													</Form>
												</div>
												<div className="col-md-6">

												</div>
											</div>
										</div>
									</Tab>
								</Tabs>
								<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

User.defaultProps = {
	registerRequest: () => { },
	registerStatus: '',
	registerSuccess: '',
	registerFail: '',
	staffStatus: '',
	staffSuccess: '',
	staffFail: ''
};

User.propTypes = {
	registerRequest: PropTypes.func,
	registerStatus: PropTypes.any,
	registerSuccess: PropTypes.any,
	registerFail: PropTypes.any,
	userStatus: PropTypes.any,
	userSuccess: PropTypes.any,
	userFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		registerSuccess: state.register.success,
		registerFail: state.register.fail,
		registerStatus: state.register.status,
		registerType: state.register.type,
		userSuccess: state.user.success,
		userFail: state.user.fail,
		userStatus: state.user.status,
		userType: state.user.type
	};
}

const mapDispatchToProps = { getUserListRequest, getUserTooltipRequest, registerRequest,deleteUserRequest };


export default connect(mapStateToProps, mapDispatchToProps)(User);
