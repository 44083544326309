import { RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;


export const changePasswordRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.RESET_PASSWORD}`,value)
        .then((response) => {
            console.log("response",response);
            if(response.status===200)
            {
                dispatch(changePasswordSuccessResult(response.data.data,response.status));
            }
            else{
                console.log("response.data",response.data);
                dispatch(changePasswordFailResult(response.data.data,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(changePasswordFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }); 
    }
};


/*
    API RESULT
 */


export const changePasswordSuccessResult = (success,status) => ({
    type: RESET_PASSWORD_SUCCESS,
    success,
    status
});

export const changePasswordFailResult = (error,status) => ({
    type: RESET_PASSWORD_FAIL,
    error,
    status
});
