import React, { Component } from 'react';
import './ResetPassword.scss';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../utils/WebConstant';
import { NavLink } from "react-router-dom";
import * as routes from '../../../utils/RouteConstant';
import { changePasswordRequest } from '../../../containers/Auth/ResetPasswordContainer/action';
import { RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } from '../../../containers/Auth/ResetPasswordContainer/Constants';
import { Cube } from 'react-preloaders2';

class ResetPassword extends Component {

	constructor(props) {
		super(props);

		// set the state of the app
		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
			error: '',
			isLoading: false,
			isValidated: true,
			isFormLoading: false,
			isValidatedToken: false,
			passwordMatch: false,
			token: "",
			formError:""
		}
		// bind component with event handlers
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		let token = this.props.match.params.token;
		if (token) {
			this.tokenValidated(token);
		}
	}

	componentDidUpdate(prevProps) {
		const { resetPasswordSuccess, resetPasswordFail, resetPasswordStatus, resetPasswordType } = this.props;
		if (resetPasswordStatus === 200) {
			if (prevProps.resetPasswordSuccess !== resetPasswordSuccess) {
				if (resetPasswordType === RESET_PASSWORD_SUCCESS) {
					this.setState({
						formError: '',
						resetPasswordSuccess: resetPasswordSuccess
					}, () => {
						let _this = this;
						setTimeout(() => {
							_this.props.history.push("/login");
						}, 500);
						webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="alert-message.password-reset-message" />);
					});
					this.forceUpdate();
				}
			}
		}
		else {
			if (resetPasswordType == RESET_PASSWORD_FAIL) {
				if (prevProps.resetPasswordFail !== resetPasswordFail) {
					if (resetPasswordStatus == 400) {
						if (this.state.isLoading) {
							this.setState({
								isValidatedToken: false,
								isLoading: false
							});
						}

						if (prevProps.resetPasswordFail !== resetPasswordFail) {
							let _this = this;
							webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.token-validate-fail-message" />);
							setTimeout(() => {
								_this.props.history.push("/login");
							}, 500);
						}
					}
					else if (resetPasswordStatus == 422) {
						this.setState({formError:resetPasswordFail});
						if (this.state.isFormLoading) {
							this.setState({
								isFormLoading: false,
							});
						}
					}
					else {
						webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
						if (this.state.isFormLoading) {
							this.setState({
								isFormLoading: false,
							});
						}
					}
				}
			}

			if (this.state.isLoading) {
				this.setState({
					resetPasswordStatus: '',
					isLoading: false,
				});
			}
		}

		if (this.state.isValidated === false) {
			this.setState({
				isLoading: false,
				isValidated: true
			});
		}
	}

	tokenValidated(token) {
		this.setState({ token: token });
	}

	// event to handle input change
	handleChange = (name, value) => {
		this.setState({ 'error': '',formError:'' });
		this.setState({ [name]: value });
		if (this.state.password === this.state.confirmPassword) {
			this.setState({ passwordMatch: false });
		}
	}

	// event to handle form submit
	async handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "isValidated": false });
		} else {
			console.log(event.target.password.value,event.target.confirmPassword.value)
			if (event.target.password.value===event.target.confirmPassword.value) {
				const params = new URLSearchParams();
				params.append('password', event.target.password.value);
				params.append('token', this.state.token);
				this.props.changePasswordRequest(params);
				this.setState({ 'isFormLoading': true, passwordMatch: false });
			}
			else {
				this.setState({ 'isFormLoading': false, passwordMatch: true });
			}
		}
		this.setState({ "isValidated": true });
	}

	showError(fieldname) {
		return this.state.formError && this.state.formError && this.state.formError[fieldname] ? <span className="text-danger">{this.state.formError[fieldname]}</span> : '';
	}

	render() {
		return (
			<div className="validi-login-layout-main validi-forget-layout">
				<div className="validi-login-form-wrapper">
					<div className="validi-logo-block">
						<img src="/images/logo.png" alt="" />
						<h3 className="main-title">{process.env.REACT_APP_NAME}</h3>
					</div>
					<div className="validi-forget-details">
						<h4><Translate id="reset-password.title" /></h4>
					</div>
					<Form noValidate validated={this.state.isValidated} onSubmit={this.handleSubmit}>
						<Form.Group className="form-group" controlId="password">
							<Form.Label><Translate id="reset-password.password" /></Form.Label>
							<Translate>
								{({ translate }) =>
									<Form.Control
										type="password"
										name="password"
										className="form-control"
										value={this.state.password ? this.state.password : ''}
										onChange={(e) => { this.handleChange(e.target.name, e.target.value) }}
										placeholder={translate("reset-password.password")}
										required
									/>
								}
							</Translate>
							<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="form-group" controlId="confirmPassword">
							<Form.Label><Translate id="reset-password.confirm-password" /></Form.Label>
							<Translate>
								{({ translate }) =>
									<Form.Control
										type="password"
										name="confirmPassword"
										className="form-control"
										value={this.state.confirmPassword ? this.state.confirmPassword : ''}
										onChange={(e) => { this.handleChange(e.target.name, e.target.value) }}
										onKeyPress={(e) => { this.handleChange(e.target.name, e.target.value) }}
										placeholder={translate("reset-password.password")}
										required
									/>
								}
							</Translate>
							<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
							{this.state.passwordMatch === true ? <p className='text-danger'><Translate id="alert-message.password-match-message" /></p> : ""}
							{this.showError("password")}
						</Form.Group>
						<div className="">
							<NavLink to={routes.LOGIN_ROUTE} className="forgot-link"><Translate id="login.title"></Translate></NavLink>
							<button className="validi-theme-btn">
								{webConst.isLoader(this.state.isFormLoading, <Translate id="reset-password.title" />)}
							</button>
						</div>
					</Form>
					<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		resetPasswordSuccess: state.resetPassword.success,
		resetPasswordFail: state.resetPassword.fail,
		resetPasswordStatus: state.resetPassword.status,
		resetPasswordType: state.resetPassword.type
	};
}

const mapDispatchToProps = { changePasswordRequest };
// const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
