import React, { Component, Fragment } from 'react';
import './Home.scss';
import { Link, NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { getClientListRequest, getClientTooltipRequest, addClientRequest } from '../../containers/ClientContainer/action';
import { setClientId } from '../../containers/AppLayoutContainer/action';
import * as routes from '../../utils/RouteConstant';
import * as webConst from '../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Cube } from 'react-preloaders2';
import Table from 'react-bootstrap/Table';
import homeIcon from "../../images/home.svg";
import journalIcon from "../../images/journal.svg";
import medicineIcon from "../../images/medicine.svg";
import messageIcon from "../../images/message.svg";
import goalIcon from "../../images/goal.svg";
// import ReactDatatable from '@mkikets/react-datatable';
import { OverlayTrigger, Overlay, Tooltip, Button, Popover, Modal, Tabs, Tab, Form } from 'react-bootstrap';
import { CLIENT_SUCCESS, CLIENT_TOOLTIP_SUCCESS, SAVE_CLIENT_SUCCESS } from '../../containers/ClientContainer/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import daLocale from "date-fns/locale/da";
import defaultPhoto from '../../images/default_avatar.png';
import { withHeader } from '../../service/ApiConfig';

class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			clientType: '',
			clientId: '',
			photoUrl: '',
			defaultPhoto: defaultPhoto,
			totalRecord: 0,
			columns: this.clientDatatable(),
			isDatatableLoading: true,
			hoverClientId: 0,
			tooltips: {},
			clientDetail: "",
			isModal: false,
			className: "",
			clientPhoto: "",
			pageSize: 20,
			pageNumber: 0,
			isMoreRecord: true,
			clients: [],
			validated: false,
			clientName: "",
			email: "",
			phone: "",
			clientBirthDate: "",
			startDate: "",
			endDate: "",
			address: "",
			city: "",
			state: "",
			postalCode: "",
			cprNumber: "",
			isCprValidated: "",
			activeKey: "clients",
			isFormLoading: false
		};

		this.getClient = this.getClient.bind(this);
		this.setClient = this.setClient.bind(this);
		this.popoverRef = React.createRef();
		this.popoverClientRef = React.createRef();
		this.clientForm = React.createRef();
	}


	componentDidMount() {
		this.getClient();
	}

	componentDidUpdate(prevProps) {
		const { homeSuccess, homeFail, homeStatus, clientId, homeType } = this.props;
		if (this.state.isDatatableLoading) {
			this.setState({
				isLoading: false,
				isDatatableLoading: false
			});
		}
		if (homeStatus === 200) {
			if (prevProps.homeSuccess !== homeSuccess) {
				if (homeType == CLIENT_SUCCESS) {
					if (this.props.homeSuccess && this.props.homeSuccess.clients) {
						let total = this.props.homeSuccess.total;
						this.setState({ clients: [...this.state.clients, ...homeSuccess.clients] });
						this.setState({
							totalRecord: total,
							isMoreRecord: true
						});
					}
				}
				if (homeType == SAVE_CLIENT_SUCCESS) {
					let _this = this;
					if (this.state.isFormLoading) {
						this.setState({
							isDatatableLoading: true,
							isFormLoading: false,
							validated: false,
							pageNumber: 0,
							activeKey: "clients",
							clients: []
						});
					}
					this.clientForm.current.reset();
					this.clearClientForm();
					setTimeout(() => {
						_this.getClient();
					}, 1000);

					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="alert-message.client-save-message-alert" />);
				}
				if (homeType == CLIENT_TOOLTIP_SUCCESS) {
					if (this.props.homeSuccess) {
						this.setState({ tooltips: this.props.homeSuccess });
					}
				}
			}
		}
		if (prevProps.homeFail !== homeFail) {
			if (this.state.isDatatableLoading) {
				this.setState({
					isLoading: false,
					isDatatableLoading: false,
					isMoreRecord: false
				});
			}
		}
	}

	getTooltipDetail = (item) => {
		this.setState({ className: webConst.ucfirst(item.client_name) });
		this.props.getClientTooltipRequest(item.id);
		this.setState({ tooltips: "" });
		var activeClient = {
			"client_id": item.id,
			"client_name": webConst.ucfirst(item.client_name),
			"client_socialid": item.cpr_number,
			"client_type": item.client_type,
			"photo": this.state.defaultPhoto
		};
		if (item.photo) {
			activeClient.photo = item.photo;
			this.setState({ clientPhoto: activeClient.photo });
		} else {
			this.setState({ clientPhoto: "" });
		}
		webConst.setClient(activeClient);
	}
	drawTooltip = () => {
		let data = [];
		let _this = this;
		//table data
		const tableData = (data) => {
			return <table className="table"><tbody>{data}</tbody></table>
		}
		// get table row data
		const tdData = (label, data) => {
			return <tr key={"tooltip-" + Math.random(1, 1000)}><th>{label}</th><td key={data}>{data}</td></tr>
		}

		if (this.state.tooltips) {
			data.push(tdData(<Translate id={"home.client-name"} />, this.state.tooltips['client_name']));
			data.push(tdData(<Translate id={"general.email"} />, this.state.tooltips['email']));
			data.push(tdData(<Translate id={"general.phone"} />, this.state.tooltips['phone']));
			data.push(tdData(<Translate id={"general.client-birth"} />, this.state.tooltips['birth_date']));
			data.push(tdData(<Translate id={"general.start-date"} />, this.state.tooltips['start_date']));
			data.push(tdData(<Translate id={"general.end-date"} />, this.state.tooltips['end_date']));
			if (this.state.tooltips['client_type'] == 0)
				data.push(tdData(<Translate id={"general.client-type"} />, <Translate id={"general.present"} />));
			else if (this.state.tooltips['client_type'] == 1)
				data.push(tdData(<Translate id={"general.client-type"} />, <Translate id={"general.former"} />));
			else if (this.state.tooltips['client_type'] == 2)
				data.push(tdData(<Translate id={"general.client-type"} />, <Translate id={"general.upcoming"} />));
			data.push(tdData(<Translate id={"general.address"} />, this.state.tooltips['address']));
			data.push(tdData(<Translate id={"general.city"} />, this.state.tooltips['city']));
			data.push(tdData(<Translate id={"general.state"} />, this.state.tooltips['state']));
			data.push(tdData(<Translate id={"general.postal-code"} />, this.state.tooltips['postal_code']));
		}
		return data;
	}

	clientDatatable = () => {
		let _this = this;
		let columns = [
			{
				key: "client_name",
				text: "",
				align: "left",
				className: "p-0 m-0 text-capitalize",
				sortable: true,
				cell: item => {
					return this.clientRow(item);
				}
			}
		];
		return columns;
	}

	setClient = (client, type = routes.GOAL_ROUTE) => {
		if (this.state.clientId !== client.client_id) {
			this.setState({ 'clientId': client.client_id, isDatatableLoading: true, isLoading: true });
			this.props.setClientId(client.client_id);
			var activeClient = {
				"client_id": client.id,
				"client_name": webConst.ucfirst(client.client_name),
				"client_socialid": client.cpr_number,
				"client_type": client.client_type,
				"photo": this.state.defaultPhoto
			};
			if (client.photo) {
				activeClient.photo = client.photo;
			}
			webConst.setClient(activeClient);
		}

		if (client.id > 0) {
			let _this = this;
			setTimeout(() => {
				_this.props.history.push(type);
			}, 100);
		}
	}

	async getClient(event) {
		let pageNumber = this.state.pageNumber + 1;
		if (!this.state.isDatatableLoading) {
			this.setState({ 'isDatatableLoading': true, 'isLoading': true });
			this.setState({ 'clientType': this.state.clientType });
		}
		if ((this.state.totalRecord > this.state.clients.length) || pageNumber == 1) {
			const params = new URLSearchParams();
			params.append('page', pageNumber);
			params.append('limit', this.state.pageSize);
			this.props.getClientListRequest(params);
			this.setState({ pageNumber: pageNumber });
		}
	}

	closeModal = () => {
		this.setState({ isModal: false });
	}

	clientRow = (item) => {
		let _this = this;
		let data = "";
		if (this.state.tooltips && typeof this.state.tooltips != "undefined") {
			data = this.drawTooltip();
		}

		const clientDetailPopover = (
			<Popover id="popover-trigger-hover-focus">
				<Popover.Header as="h3">{webConst.ucfirst(item.client_name)}</Popover.Header>
				<Popover.Body>
					{(item.photo) ? <img src={item.photo} className='rounded' height={"100px"} /> : <img src={_this.state.defaultPhoto} className='rounded' height={"100px"} />}
					<Table className='table'>
						<tbody>
							{data}
						</tbody>
					</Table>
				</Popover.Body>
			</Popover>
		);
		return (
			<div className='row mt-2'>
				<div className='col-md-6 pl-2'>
					<div className="d-lg-none">
						<Link to="#" className='text-capitalize' onClick={e => { this.getTooltipDetail(item); this.setState({ isModal: true }) }} >{item.client_name}</Link>
					</div>
					<div className="d-none d-lg-block">
						<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={clientDetailPopover} delay={500} onEntered={() => { this.getTooltipDetail(item) }}>
							<Link to="#" className='text-capitalize' onClick={e => { this.setClient(item, routes.GOAL_ROUTE) }} >{item.client_name}</Link>
						</OverlayTrigger>
					</div>
					<div className='cpr-number'>
						<i className="glyphicon glyphicon-tag"></i>
						<Translate id="home.cpr-number" /> : {item.cpr_number}
					</div>
				</div>
				<div className='col-md-6  d-flex justify-content-sm-start justify-content-md-end d-flex justify-content-lg-end'>
					<div className="d-flex justify-content-end">
						<span className='p-1'>
							<Link to="#" className='btn btn-sm btn-outline-secondary'>
								<img src={goalIcon} height={"20"} alt="goal" />
							</Link>
						</span>
						<span className='p-1'>
							<Link to="#" className='btn btn-sm btn-outline-secondary'>
								<img src={journalIcon} height={"20"} alt="journal" />
							</Link>
						</span>
						<span className='p-1'>
							<Link to="#" onClick={e => { this.setClient(item, routes.MEDICINE_PLAN_ROUTE) }} className='btn btn-sm btn-outline-secondary'>
								<img src={medicineIcon} height={"20"} alt="medicine" />
							</Link>
						</span>
						<span className='p-1'>
							<Link to="#" className='btn btn-sm btn-outline-secondary'>
								<img src={messageIcon} height={"20"} alt="message" />
							</Link>
						</span>
					</div>
				</div>
			</div>
		);
	}

	clientSubmitHandler = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "validated": true });
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			const params = new URLSearchParams();
			params.append('user_id', webConst.getUserId());
			params.append('client_id', this.state.clientId);
			params.append('client_name', event.target.clientName.value);
			params.append('email', event.target.email.value);
			params.append('phone', event.target.phone.value);
			params.append('client_birth_date', event.target.clientBirthDate.value);
			params.append('start_date', event.target.startDate.value);
			params.append('end_date', event.target.endDate.value);
			params.append('address', event.target.address.value);
			params.append('city', event.target.city.value);
			params.append('state', event.target.state.value);
			params.append('postal_code', event.target.postalCode.value);
			if (typeof event.target.isCprValidated == undefined)
				params.append('is_cpr_validated', event.target.isCprValidated.value);
			if (typeof event.target.cprNumber == undefined)
				params.append('cpr_number', event.target.cprNumber.value);
			params.append('client_type', event.target.clientType.value);
			this.props.addClientRequest(params);
			this.setState({ 'isFormLoading': true, validated: false });
		}
		this.setState({ "validated": true });
	}

	handleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		this.setState({ [name]: value });
	}

	handleChangeDateRange = (name, value) => {
		this.setState({ [name]: value });
	}

	setActiveKey = (key) => {
		this.setState({ "activeKey": key });
	}

	clearClientForm = (event) => {
		this.clientForm.current.reset();
		this.setState({
			validated: true,
			clientName: "",
			email: "",
			phone: "",
			clientBirthDate: "",
			startDate: "",
			endDate: "",
			address: "",
			city: "",
			state: "",
			postalCode: "",
			cprNumber: "",
			isCprValidated: "",
		});
		this.clientForm.current.classList.remove("was-validated");
	}

	render() {
		const { homeSuccess } = this.props;
		let _this = this;
		let clientTypes = [];
		clientTypes.push(<Translate key="default-type-0">
			{({ translate }) =>
				<option key="client-0" value="">{translate("general.select-client")}</option>
			}
		</Translate>);
		["present", "former", "upcoming"].map((item, index) => {
			clientTypes.push(<Translate key={"key-" + index}>
				{({ translate }) => <option key={"type-" + index + 1} value={index}>{translate("general." + item)}</option>
				}
			</Translate>
			);
			return "";
		});
		return (
			<div className="validi-main-layout validi-clinets-layout">
				<div className="container">
					<div className="validi-our-clinets-details">
						<div className="validi-tabbar-wrapper">
							<Tabs id="controlled-tab-example" activeKey={this.state.activeKey} defaultActiveKey="clients" onSelect={(id) => { this.setActiveKey(id) }}>
								<Tab eventKey="clients" title={<Translate id="home.title" />}>
									<div className="validi-journal-msg-wrapper">
										<div>
											<InfiniteScroll
												dataLength={this.state.clients && this.state.clients.length ? this.state.clients.length : 0}
												next={this.getClient}
												hasMore={this.state.isMoreRecord}
												loader={this.state.isDatatableLoading ? <h4>Loading...</h4> : ""}
											>
												{this.state.clients.map((client, index) => (
													<div key={index} className='border-bottom'>
														{this.clientRow(client)}
													</div>
												))}
											</InfiniteScroll>

											<Modal show={this.state.isModal} fullscreen={"sm-down"} onHide={() => { this.closeModal() }}>
												<Modal.Header closeButton>
													<Modal.Title>{webConst.ucfirst(this.state.className)}</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													<Table striped>
														<tbody>
															<tr key={"tooltip-" + Math.random(1, 1000)}>
																<td colSpan={2}>
																	{<img src={this.state.clientPhoto && this.state.clientPhoto != "" ? this.state.clientPhoto : this.state.defaultPhoto} height={"100px"} />}
																</td>
															</tr>
															{typeof this.state.tooltips != "undefined" ? this.drawTooltip() : ""}
														</tbody>
													</Table>
												</Modal.Body>
											</Modal>
										</div>
										<div>
											{
												this.props.homeSuccess && this.props.homeSuccess.clients && this.props.homeSuccess.clients.length === 0 ?
													<div className='alert alert-danger'>
														<Translate id="general.clients"></Translate> <Translate id="alert-message.not-found"></Translate>
													</div>
													: ""
											}
										</div>
									</div>
								</Tab>
								<Tab eventKey="add-client" title={<Translate id="home.add-client" />}>
									<div className="validi-tabbar-inner-header">
										<h4 className="validi-subtitle"><Translate id="home.add-client" /></h4>
									</div>
									<div className="validi-journal-msg-wrapper">
										<div className="row">
											<div className="col-md-12">
												<Form noValidate validated={this.state.validated} onSubmit={this.clientSubmitHandler} ref={this.clientForm}>
													<Form.Group className="form-group" controlId="client_name">
														<Form.Label><Translate id="home.client-name" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="text"
																	name="clientName"
																	className="form-control"
																	value={this.state.clientName ? this.state.clientName : ''}
																	onChange={(e) => { this.handleChange(e) }}
																	placeholder={translate("home.client-name")}
																	required
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="email">
														<Form.Label><Translate id="general.email" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="email"
																	name="email"
																	className="form-control"
																	value={this.state.email ? this.state.email : ''}
																	onChange={(e) => { this.handleChange(e) }}
																	placeholder={translate("general.email")}
																	required
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="phone">
														<Form.Label><Translate id="general.phone" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="text"
																	name="phone"
																	className="form-control"
																	value={this.state.phone ? this.state.phone : ''}
																	onChange={(e) => { this.handleChange(e) }}
																	placeholder={translate("general.phone")}
																	required
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="client_birth">
														<Form.Label><Translate id="general.client-birth" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<DatePicker
																	selected={this.state.clientBirthDate}
																	onChange={(date) => { this.handleChangeDateRange('clientBirthDate', date) }}
																	selectsStart
																	startDate={this.state.clientBirthDate}
																	dateFormat="dd-MM-yyyy"
																	className="form-control"
																	withPortal
																	locale={daLocale}
																	placeholderText={translate("general.client-birth")}
																	name={"clientBirthDate"}
																	required
																	autoComplete='off'
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="start_date">
														<Form.Label><Translate id="general.start-date" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<DatePicker
																	selected={this.state.startDate}
																	onChange={(date) => { this.handleChangeDateRange('startDate', date) }}
																	selectsStart
																	startDate={this.state.startDate}
																	endDate={this.state.endDate}
																	dateFormat="dd-MM-yyyy"
																	className="form-control"
																	withPortal
																	locale={daLocale}
																	placeholderText={translate("general.start-date")}
																	name={"startDate"}
																	required
																	autoComplete='off'
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="start_date">
														<Form.Label><Translate id="general.end-date" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<DatePicker
																	selected={this.state.endDate}
																	onChange={(date) => { this.handleChangeDateRange('endDate', date) }}
																	selectsEnd
																	startDate={this.state.startDate}
																	endDate={this.state.endDate}
																	minDate={this.state.startDate}
																	dateFormat="dd-MM-yyyy"
																	className="form-control"
																	withPortal
																	locale={daLocale}
																	placeholderText={translate("general.end-date")}
																	name={"endDate"}
																	required
																	autoComplete='off'
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="start_date">
														<Form.Label><Translate id="general.address" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="textarea"
																	name="address"
																	value={this.state.address ? this.state.address : ""}
																	onChange={(e) => { this.handleChange(e) }}
																	placeholder={translate("general.address")}
																	as="textarea"
																	rows={3}
																	required
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="city">
														<Form.Label><Translate id="general.city" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="text"
																	name="city"
																	className="form-control"
																	value={this.state.city ? this.state.city : ''}
																	onChange={(e) => { this.handleChange(e) }}
																	placeholder={translate("general.city")}
																	required
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="state">
														<Form.Label><Translate id="general.state" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="text"
																	name="state"
																	className="form-control"
																	value={this.state.state ? this.state.state : ''}
																	onChange={(e) => { this.handleChange(e) }}
																	placeholder={translate("general.state")}
																	required
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="postal_code">
														<Form.Label><Translate id="general.postal-code" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="text"
																	name="postalCode"
																	className="form-control"
																	value={this.state.postalCode ? this.state.postalCode : ''}
																	onChange={(e) => { this.handleChange(e) }}
																	placeholder={translate("general.postal-code")}
																	required
																/>
															}
														</Translate>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="form-group" controlId="clientType">
														<Form.Label><Translate id="general.select-client-type" /></Form.Label>
														<Form.Select
															type="select"
															name="clientType"
															value={this.state.clientType ? this.state.clientType : ""}
															onChange={(e) => { this.handleChange(e) }}
															required
														>
															{clientTypes}
														</Form.Select>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<div className="">
														<button className="validi-theme-btn">
															{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}
														</button>
														<button type="button" className="validi-theme-btn clear-btn" onClick={this.clearClientForm}><Translate id="general.clear" /></button>
													</div>
												</Form>
											</div>
											<div className="col-md-6">

											</div>
										</div>
									</div>
								</Tab>
							</Tabs>
							<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Home.defaultProps = {
	getClientListRequest: () => { },
	setClientId: () => { },

	homeStatus: '',
	homeSuccess: '',
	homeFail: ''
};

Home.propTypes = {
	getClientListRequest: PropTypes.func,
	setClientId: PropTypes.func,

	homeStatus: PropTypes.any,
	homeSuccess: PropTypes.any,
	homeFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		homeSuccess: state.client.success,
		homeFail: state.client.fail,
		homeStatus: state.client.status,
		homeType: state.client.type,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : ""
	};
}

const mapDispatchToProps = { getClientListRequest, setClientId, getClientTooltipRequest, addClientRequest };


export default connect(mapStateToProps, mapDispatchToProps)(Home);
