import { USER_SUCCESS, USER_FAIL,USER_TOOLTIP_SUCCESS,USER_TOOLTIP_FAIL,USER_DELETE_SUCCESS,USER_DELETE_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case USER_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case USER_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case USER_TOOLTIP_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case USER_TOOLTIP_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case USER_DELETE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
      default:
        return state;
  }
}