import React, { Component } from 'react';
// import Select from "react-select";
import { Tabs, Tab, Table, FormGroup, Label, Input, Popover, OverlayTrigger } from 'react-bootstrap';
import './DetoxDispense.scss';
import { Link } from 'react-router-dom';
import * as webConst from '../../../../utils/WebConstant';
import * as routes from '../../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import backArrow from '../../../../images/back-arrow.svg';
import { getDispenseDetoxDosageRequest, dispenseDosageStatusRequest, setDetoxActiveTab } from '../../../../containers/Medicines/DetoxPlanContainer/action';
import moment from "moment";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import { GET_DISPENSE_DOSAGE_SUCCESS, DISPENSE_DOSAGE_STATUS_SUCCESS, DISPENSE_DOSAGE_STATUS_FAIL } from '../../../../containers/Medicines/DetoxPlanContainer/Constants';
import { Cube } from 'react-preloaders2';

class DetoxDispense extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDetoxLoading: true,
			clientId: '',
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			startCalendar: moment().startOf('week').add("1", "days").format("YYYY-MM-DD"),
			endCalendar: moment().endOf('week').add("1", "days").format("YYYY-MM-DD"),
			dispenseDosageEvents: [],
			detoxActiveTab: "detox-dispense",
			isLoading: true
		};
		this.calendarRef = React.createRef();
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
			let params = new URLSearchParams();
			params.append('client_id', webConst.getClientId());
			params.append('start', this.state.startCalendar);
			params.append('end', this.state.endCalendar);
			this.getDispseDosageEvents(params);
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { detoxSuccess, detoxStatus, detoxType, detoxActiveTab } = this.props;

		if (detoxStatus === 200) {
			if (detoxType == GET_DISPENSE_DOSAGE_SUCCESS) {
				if (prevProps.detoxSuccess !== detoxSuccess) {
					this.setState({ isLoading: false });
					if (detoxSuccess.dosages && detoxSuccess.dosages.length) {
						this.setState({ dispenseDosageEvents: detoxSuccess.dosages });
					}
				}
			}

			if (detoxType == DISPENSE_DOSAGE_STATUS_SUCCESS) {
				if (prevProps.detoxSuccess !== detoxSuccess) {
					if (detoxSuccess.dosages && detoxSuccess.dosages.length) {
						this.setState({ dispenseDosageEvents: detoxSuccess.dosages });
					}
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.dispense-status-message" />);
				}
			}
		}

		if (detoxStatus === 400) {
			if (this.state.isLoading) {
				this.setState({
					isDetoxLoading: false,
					isLoading: false
				});
			}
			if (detoxType == DISPENSE_DOSAGE_STATUS_FAIL) {
				webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="alert-message.something-wrong" />);
			}
		}
	}

	renderEventContent(eventInfo, event) {
		let eventId = eventInfo.event.id;
		let medicineName = "";
		let medicineType = "";
		let dosage = "";
		let description = "";
		let image = "";
		let status = 0;
		if (eventInfo.event.extendedProps) {
			medicineName = eventInfo.event.title;
			medicineType = webConst.ucfirst(eventInfo.event.extendedProps.medicine_type);
			dosage = eventInfo.event.extendedProps.dosage_amt;
			description = eventInfo.event.extendedProps.description;
			image = webConst.getMedicineUrl(eventInfo.event.extendedProps.image);
			status = parseInt(eventInfo.event.extendedProps.dispense_status);
		}

		return (
			<OverlayTrigger
				trigger={['hover', 'focus']}
				key={"popover-" + eventId}
				placement={"auto"}
				overlay={
					<Popover id={`popover-positioned-` + eventId}>
						<Popover.Header as="h3"><Translate id="medicine.medicine-detail" /></Popover.Header>
						<Popover.Body>
							<div className="validi-table-data-wrapper">
								<Table className="table" responsive="md">
									<tbody>
										<tr>
											<td><Translate id="medicine.medicine" /> :</td>
											<td className="validi-date-text">{medicineName}</td>
										</tr>
										<tr>
											<td><Translate id="medicine.dosage" /> : </td>
											<td className="validi-date-text">{dosage}</td>
										</tr>
										<tr>
											<td><Translate id="medicine.medicine-type" /> : </td>
											<td className="validi-date-text">{medicineType}</td>
										</tr>
										<tr>
											<td><Translate id="medicine.dispense-status" /> : </td>
											<td className="validi-date-text">{status === 0 ? <Translate id="medicine.no" /> : <Translate id="medicine.yes" />}</td>
										</tr>
										<tr>
											<td className="text-danger"><Translate id="medicine.reason" /> : </td>
											<td className="validi-date-text text-danger">{description}</td>
										</tr>
									</tbody>
								</Table>
								{image ?
									<img src={image} alt={image} width={250} />
									: ""
								}
							</div>
						</Popover.Body>
					</Popover>
				}
			>
				<div>
					<span>{eventInfo.timeText.split("-")[0]} &nbsp;</span>
					<small>{eventInfo.event.title}</small>
				</div>
			</OverlayTrigger>
		);
	}

	getDispseDosageEvents = (params) => {
		this.setState({ isLoading: true });
		this.props.getDispenseDetoxDosageRequest(params);
	}

	getDosageEvents = (info, successCallback) => {
		let start = moment(info.startStr).format("YYYY-MM-DD");
		let end = moment(info.endStr).format("YYYY-MM-DD");
		if (this.state.startCalendar != start) {
			let params = new URLSearchParams();
			params.append('client_id', webConst.getClientId());
			params.append('start', start);
			params.append('end', end);
			this.setState({ startCalendar: start, endCalendar: end });
			this.setState({ dispenseDosageEvents: [] });
			this.getDispseDosageEvents(params);
		}
		if (this.state.dispenseDosageEvents) {
			successCallback(this.state.dispenseDosageEvents);
		}
	}

	eventClick = (event) => {
		var extendedProps=event.event.extendedProps;
		let dispenseStatus = extendedProps.dispense_status ? parseInt(extendedProps.dispense_status) : "";
		let detoxStatus = extendedProps.detox_status ? parseInt(extendedProps.detox_status) : "";
		let dosage_amount = extendedProps.dosage_amt ? parseFloat(extendedProps.dosage_amt) : 0;
		let dosageId = extendedProps.dosageId ? extendedProps.dosageId:"";
		if (dispenseStatus == 0 && detoxStatus == 0) {
			let params = new URLSearchParams();
			params.append('detox_id', extendedProps.detox_id);
			params.append('user_id', webConst.getUserId());
			params.append('dosage_id', dosageId);
			params.append('status', dispenseStatus == 0 ? 1 : 0);
			params.append('client_id', extendedProps.client_id);
			params.append('dosage_date', extendedProps.dosage_date);
			params.append('slot_id', extendedProps.slot_id);
			params.append('dosage_amount', dosage_amount);
			params.append('start', this.state.startCalendar);
			params.append('end', this.state.endCalendar);
			this.props.dispenseDosageStatusRequest(params);
		}
	}

	activeTab = (id) => {
		if (id == 'add-detox') {
			this.props.history.push(routes.ADD_DETOX_ROUTE);
			this.props.setDetoxActiveTab("active-detox");
		}
		else if (id == 'detox-dispense') {
			this.props.history.push(routes.DETOX_DISPENSE_ROUTE);
			this.props.setDetoxActiveTab("active-detox");
		}
		else {
			this.props.history.push(routes.DETOX_PLAN_ROUTE);
			this.props.setDetoxActiveTab(id);
		}
	}

	render() {

		return (
			<div className="validi-main-layout validi-detox-medicine-layout">
				<div>
					<div className="container">
						<div className="validi-back-btn-wrapper">
							<Link to={routes.MEDICINE_PLAN_ROUTE}>
								<i>
									<img src={backArrow} />
								</i>
								<Translate id="medicine.back-to-medicine-plan" />
							</Link>
						</div>
						<h1 className="main-title"><Translate id="medicine.detox-plan" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
								<div className="validi-select-input">
								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example" defaultActiveKey={"detox-dispense"} activeKey={"detox-dispense"} onSelect={(id) => { this.activeTab(id) }}>
									<Tab eventKey="active-detox" title="Detox plans">
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="medicine.detox-plan" /></h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<div className='table-responsive-md'>
													detox list
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add-detox" title="Add detox plan">
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="medicine.detox-plan" /></h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">

											</div>
										</div>
									</Tab>
									<Tab eventKey="detox-dispense" title="Detox dispense">
										{this.state.dispenseDosageEvents ?
											<FullCalendar
												ref={this.calendarRef}
												initialView='timeGridDay'
												height={"auto"}
												plugins={[dayGridPlugin, timeGridPlugin]}
												headerToolbar={{
													left: 'title',
													right: 'today,timeGridWeek,timeGridDay,prev,next'
												}}
												events={(info, successCallback) => { this.getDosageEvents(info, successCallback) }}
												eventContent={this.renderEventContent} // custom render function
												eventClick={(event) => { this.eventClick(event) }}
												eventRender={this.eventRender}
												allDaySlot={false}
												locale={"da"}
												slotMinTime={'05:00:00'}
												slotMaxTime={'24:00:00'}
												slotLabelFormat={
													{
														hour: "numeric",
														minute: "2-digit",
														hour12: false
													}
												}
												eventTimeFormat={
													{
														hour12: false,
														hour: "numeric",
														minute: "2-digit"
													}
												}
												axisFormat={'H:mm'}
												scrollTime={'07:00:00'}
												dayMaxEvents={true}
												editable={true}
												selectable={true}
												firstDay={"1"}
											/> : ""
										}
									</Tab>
									<Tab eventKey="detox-dispense-history" title="Detox Dispense histories">
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle">Detox Dispense histories</h4>
												</div>
												<div className="validi-table-filter-box">
													<form>
														<div className="form-group">
															{/* <Select placeholder="All Sports" /> */}
														</div>
														<div className="form-group">
															<input type="search" className="form-control" placeholder="Search…" />
														</div>
													</form>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												Detox Dispense histories
											</div>
										</div>
									</Tab>
									<Tab eventKey="detox-suspended" title="Suspended Detox">
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle">Suspended Detox</h4>
												</div>
												<div className="validi-table-filter-box">
													<form>
														<div className="form-group">
															{/* <Select placeholder="All Sports" /> */}
														</div>
														<div className="form-group">
															<input type="search" className="form-control" placeholder="Search…" />
														</div>
													</form>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												Suspended Detox
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</div>
						<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		)
	}
}

DetoxDispense.defaultProps = {
	clientId: '',
	detoxSuccess: "",
	detoxFail: "",
	detoxStatus: "",
	detoxId: "",
	detoxActiveTab: "detox-dispense"
};

DetoxDispense.propTypes = {
	clientId: PropTypes.any,
	detoxSuccess: PropTypes.any,
	detoxFail: PropTypes.any,
	detoxStatus: PropTypes.any,
	detoxType: PropTypes.any,
	detoxId: PropTypes.any,
	detoxActiveTab: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		detoxSuccess: state.detoxPlan.success,
		detoxFail: state.detoxPlan.fail,
		detoxStatus: state.detoxPlan.status,
		detoxType: state.detoxPlan.type,
		detoxId: state.detoxPlan.detoxId,
		detoxActiveTab: state.detoxPlan.detoxActiveTab,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : ""
	};
}

const mapDispatchToProps = {
	getDispenseDetoxDosageRequest,
	dispenseDosageStatusRequest,
	setDetoxActiveTab
};

export default connect(mapStateToProps, mapDispatchToProps)(DetoxDispense);