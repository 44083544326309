import { RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } from './Constants';

const initialState = {
    success: [],
    fail: '',
    status: ''
};

export default function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                success: action.success,
                fail: '',
                status: action.status,
                type: action.type
            };
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                success: [],
                fail: action.error,
                status: action.status,
                type: action.type
            };
        default:
            return state;
    }
}
