/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import appLayoutReducer from '../containers/AppLayoutContainer/reducer';
import homeReducer from '../containers/HomeContainer/reducer';

import clientReducer from '../containers/ClientContainer/reducer';

import loginReducer from '../containers/Auth/LoginContainer/reducer';
import logoutReducer from '../containers/Auth/LogoutContainer/reducer';
import forgotPasswordReducer from '../containers/Auth/ForgotPasswordContainer/reducer';
import resetPasswordReducer from '../containers/Auth/ResetPasswordContainer/reducer';
import registerReducer from '../containers/Auth/RegisterContainer/reducer';
import userReducer from '../containers/UserContainer/reducer';

import goalReducer from '../containers/Goals/GoalContainer/reducer';
import completedGoalReducer from '../containers/Goals/CompletedGoalContainer/reducer';

import PersonalJournalReducer from '../containers/Journals/PersonalJournalContainer/reducer';
import AddPersonalJournalReducer from '../containers/Journals/AddPersonalJournalContainer/reducer';

import MessageReducer from '../containers/Messages/MessageContainer/reducer';
import SendMessageReducer from '../containers/Messages/SendMessageContainer/reducer';

import MedicineReducer from '../containers/Medicines/MedicineContainer/reducer'; 
import TimeSlotReducer from '../containers/Medicines/TimeSlotContainer/reducer'; 
import PackageReducer from '../containers/Medicines/PackageContainer/reducer'; 
import MedicinePlanDetailReducer from '../containers/Medicines/MedicinePlanDetailContainer/reducer';
import StockReducer from '../containers/Medicines/StockContainer/reducer';
import DetoxPlanReducer from '../containers/Medicines/DetoxPlanContainer/reducer';

import pnMedicineReducer from '../containers/Medicines/Pn/PnPlanContainer/reducer';
import alcoholMedicineReducer from '../containers/Medicines/Alcohol/AlcoholPlanContainer/reducer';

import topicReducer from '../containers/TopicContainer/reducer';
import Medicine from '../components/Medicine/Medicine';

const createReducer = combineReducers({
    appLayout:appLayoutReducer,
    login:loginReducer,
    logout:logoutReducer,
    register:registerReducer,
    home:homeReducer,
    client:clientReducer,
    localize: localizeReducer,
    goal:goalReducer,
    completedGoal:completedGoalReducer,
    personalJournal:PersonalJournalReducer,
    message:MessageReducer,
    addPersonalJournal:AddPersonalJournalReducer,
    sendMessage:SendMessageReducer,
    medicinePlanDetail:MedicinePlanDetailReducer,
    stock:StockReducer,
    detoxPlan:DetoxPlanReducer,
    medicine:MedicineReducer,
    medicinePackage:PackageReducer,
    pnPlan:pnMedicineReducer,
    alcoholPlan:alcoholMedicineReducer,
    topic:topicReducer,
    user:userReducer,
    timeSlot:TimeSlotReducer,
    forgotPassword:forgotPasswordReducer,
    resetPassword:resetPasswordReducer
});

export default createReducer;
