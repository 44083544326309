import { MEDICINE_SUCCESS, MEDICINE_FAIL } from './Constants';

const initialState = {
  isLoading: true
};

export default function stockReducer(state = initialState, action) {
  switch (action.type) {
    case MEDICINE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case MEDICINE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
    default:
      return state;
  }
}