import { USER_SUCCESS, USER_FAIL,USER_TOOLTIP_SUCCESS,USER_TOOLTIP_FAIL,USER_DELETE_SUCCESS,USER_DELETE_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getUserListRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.USER}?`+value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(userSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(userFailResult(response.data.responseMessage,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(userFailResult(error.response.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }); 
    }
};

export const getUserTooltipRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.USER}/`+value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(userTooltipSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(userTooltipFailResult(response.data.responseMessage,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(userTooltipFailResult(error.response.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};


export const deleteUserRequest = (value) => {
    return dispatch => {
        axios.delete(`${webConst.USER}/`+value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(userDeleteSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(userDeleteFailResult(response.data.responseMessage,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(userDeleteFailResult(error.response.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};


/*
    API RESULT
 */


export const userSuccessResult = (success,status) => ({
    type: USER_SUCCESS,
    success,
    status
});

export const userFailResult = (error,status) => ({
    type: USER_FAIL,
    error,
    status
});

export const userTooltipSuccessResult = (success,status) => ({
    type: USER_TOOLTIP_SUCCESS,
    success,
    status
});

export const userTooltipFailResult = (error,status) => ({
    type: USER_TOOLTIP_FAIL,
    error,
    status
});

export const userDeleteSuccessResult = (success,status) => ({
    type: USER_DELETE_SUCCESS,
    success,
    status
});

export const userDeleteFailResult = (error,status) => ({
    type: USER_DELETE_FAIL,
    error,
    status
});