/**
 * Create the store with dynamic reducers
 */
import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import * as webConst from '../utils/WebConstant';
import thunk from 'redux-thunk';
import createReducer from './reducers';
import { Translate } from 'react-localize-redux';
// import { Redirect } from "react-router-dom";
import logger from 'redux-logger';
import { withHeader } from '../service/ApiConfig';

const loggingMiddleware = (store) => (next) => (action) => {
    let lastLoginTime = webConst.getLoginTime();
    let currentTime = new Date().valueOf();
    //update token
    if (action.status === 100) {
        withHeader.headers.Authorization = webConst.getUserToken();
        next(action);
    }
    // console.log(action);
    if (lastLoginTime < currentTime) {
        if (action.status === 401) {
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            webConst.removeUserToken();
            webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.login-message" />);
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            next(action);
            // <Redirect to={"login"}/>
            window.location.href = 'login';
        }
        next(action);
    }
    else if (typeof action.error != 'undefined' && typeof action.error != 'undefined') {
        if (action.type === 'LOGIN_FAIL' && action.status === 401) {
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            webConst.removeUserToken();
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            window.location.href = 'login';
            webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.login-message" />);
        }
        else if (action.status === 401) {
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            webConst.removeUserToken();
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.login-message" />);
            window.location.href = 'login';
           // this.props.location.push('/login');
        }
        next(action);
    }else{
        if (typeof action.error == undefined && typeof action.status == undefined) {
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            webConst.removeUserToken();
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.login-message" />);
            window.location.href = 'login';
            next(action);
        }
        else{
            webConst.emitter.emit(webConst.LOGIN_EVENT, "logedin");
            next(action);
        }
    }
}


export default function configureStore() {
    const store = createStore(createReducer,
        applyMiddleware(thunk, loggingMiddleware)
        // applyMiddleware(thunk, logger,loggingMiddleware)
    );
    return store;
}
