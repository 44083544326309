import { LOGIN_SUCCESS, LOGIN_FAIL } from './Constants';

const initialState = {
    success: [],
    fail: '',
    status: '',
    loggedIn:false
};

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                success: action.success,
                fail: '',
                status: action.status,
                loggedIn:action.loggedIn
            };
        case LOGIN_FAIL:
            return {
                ...state,
                success: [],
                fail: action.error,
                status: action.status,
                loggedIn:false
            };
        default:
            return state;
    }
}
