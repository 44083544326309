export const TIME_SLOT_SUCCESS = 'TIME_SLOT_SUCCESS';
export const TIME_SLOT_FAIL = 'TIME_SLOT_FAIL';

export const ADD_TIME_SLOT_SUCCESS = 'ADD_TIME_SLOT_SUCCESS';
export const ADD_TIME_SLOT_FAIL = 'ADD_TIME_SLOT_FAIL';

export const UPDATE_TIME_SLOT_SUCCESS = 'UPDATE_TIME_SLOT_SUCCESS';
export const UPDATE_TIME_SLOT_FAIL = 'UPDATE_TIME_SLOT_FAIL';

export const DELETE_TIME_SLOT_SUCCESS = 'DELETE_TIME_SLOT_SUCCESS';
export const DELETE_TIME_SLOT_FAIL = 'DELETE_TIME_SLOT_FAIL';